import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CallEnded from '../../containers/CallEnded';
import Conference from '../../containers/Conference';
import LoadingConference from '../../containers/LoadingConference';
import SignIn from '../../containers/SignIn';
import { SquadChatCommunicator } from '../../services/SquadChatCommunicator';
import {
  IQueryParameters,
  queryStringToObject,
} from '../../services/utils/stringUtils';
import { SquadVoiceCommunicator } from '../../services/voice/SquadVoiceCommunicator';
import { Events } from '../../services/voice/types/types';

const Home: React.FC = () => {
  const [sip, setSip] = useState<SquadVoiceCommunicator>();
  const [chat, setChat] = useState<SquadChatCommunicator>();
  const location = useLocation();
  const [conferenceId, setConferenceId] = useState('');
  const [pin, setPin] = useState('');
  const [params, setParams] = useState<IQueryParameters>();
  const [layout, setLayout] = useState(1);

  const onSignIn = useCallback(() => {
    setLayout(2);
  }, []);

  const onLoading = useCallback(() => {
    if (sip) {
      sip.subscribe(event => {
        if (event === Events.CONNECTED && layout === 2) {
          setLayout(3);
        }
      });
    }
  }, [layout, sip]);

  const onEndCall = useCallback(() => {
    setLayout(4);
  }, []);
  const onReturn = useCallback(() => {
    setLayout(1);
  }, []);
  const addSipData = useCallback(async (sipData: SquadVoiceCommunicator) => {
    setSip(sipData);
  }, []);

  const addChatData = useCallback(async (chatData: SquadChatCommunicator) => {
    setChat(chatData);
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      const values = queryStringToObject(location.search);
      setParams(values);
      setConferenceId(values.conferenceId);
      setPin(values.pin || '');
    }
  }, [location]);

  const renderLayout = useCallback(() => {
    switch (layout) {
      case 1:
        return (
          <SignIn
            signInAction={onSignIn}
            addSipData={addSipData}
            conferenceId={conferenceId}
            pin={pin}
            addChatData={addChatData}
            params={params}
          />
        );
      case 2:
        return <LoadingConference onLoading={onLoading} />;
      case 3:
        return (
          <Conference
            endCall={onEndCall}
            sip={sip}
            conferenceId={conferenceId}
            pin={pin}
            chat={chat}
            params={params}
          />
        );
      case 4:
        return <CallEnded onReturn={onReturn} />;
      default:
        return (
          <SignIn
            signInAction={onSignIn}
            addSipData={addSipData}
            conferenceId={conferenceId}
            pin={pin}
            addChatData={addChatData}
            params={params}
          />
        );
    }
  }, [
    layout,
    onSignIn,
    onLoading,
    onEndCall,
    onReturn,
    addSipData,
    sip,
    conferenceId,
    pin,
    addChatData,
    chat,
    params,
  ]);

  return <>{renderLayout()}</>;
};

export default Home;
