import React from 'react';
import Loader from '../Loader';

import './styles.scss';
import logo from '../../assets/logo-squad-transparent.png';
import { useAuth } from '../../hooks/auth';
import { parseName } from '../../helpers/parseName';

const CardLoadingConference: React.FC = () => {
  const { user } = useAuth();

  return (
    <div className="card-loading-conference">
      <span className="title">
        {`Seja bem-vindo(a), ${parseName(user.name)}.`}
      </span>
      <div className="loader-container">
        <Loader />
      </div>
      <p>Você está sendo redirecionado para a sala de conferencia...</p>
      <img src={logo} alt="squad" />
    </div>
  );
};

export default CardLoadingConference;
