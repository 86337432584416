import React from 'react';

import './styles.scss';
import logo from '../../assets/logo-squad-transparent.png';

interface IProps {
  onReturn(): void;
}

const CallEnded: React.FC<IProps> = props => {
  const { onReturn } = props;

  return (
    <div className="ended-conference">
      <div className="card-ended-conference">
        <span className="title">Você saiu da reunião!</span>
        <div className="ended-conference-container">
          <button onClick={onReturn} type="button" style={{ borderWidth: 0.5 }}>
            Voltar à tela inicial
          </button>
        </div>
        <img src={logo} alt="squad" />
      </div>
    </div>
  );
};

export default CallEnded;
