import React, { ReactNode } from 'react';
import './styles.scss';
import { IoMdMicOff } from 'react-icons/io';
import { TiMicrophone } from 'react-icons/ti';
import { IoHandRight } from 'react-icons/io5';
import {
  CallActionsType,
  IParticipantItem,
} from '../../../interfaces/participant';
import { parseName } from '../../../helpers/parseName';

interface IProps {
  name: string;
  profileImgUrl?: string;
  talking: boolean;
  mic: CallActionsType;
  handsUp: boolean;
  onScreenShare?: boolean;
  userThatIsSharing: string | undefined;
  user: IParticipantItem;
}

const returnMicIcon = (icon: CallActionsType): ReactNode => {
  if (icon === 'off') {
    return <IoMdMicOff />;
  }
  return <TiMicrophone />;
};

const returnHandsUpIcon = () => {
  return <IoHandRight />;
};

const inicialLetters = (name: string) => {
  if (name.split(' ')[1]) {
    const nameWithSpaces = name[0] + name.split(' ')[1][0];
    return nameWithSpaces.toUpperCase();
  }
  const nameWithoutSpaces = name.length >= 2 ? name[0] + name[1] : name[0];
  return nameWithoutSpaces.toUpperCase();
};

const UserLayout: React.FC<IProps> = ({
  name,
  profileImgUrl,
  mic,
  talking,
  handsUp,
  userThatIsSharing,
}) => {
  return (
    <div className="users-layout-content-box">
      {profileImgUrl && name ? (
        <img
          alt={name}
          src={profileImgUrl}
          className={`users-layout-content-user ${
            talking ? 'on' : 'off'
          } ${userThatIsSharing}`}
        />
      ) : (
        name && (
          <div className="onScreenShare">
            <div
              className={`users-layout-content-user ${
                talking ? 'on' : 'off'
              } ${userThatIsSharing}`}
            >
              {inicialLetters(name)}
            </div>
          </div>
        )
      )}

      <div className="users-layout-content-info">
        <div className="users-layout-content-name">{parseName(name)}</div>
        <div className="users-layout-align-mic-box">
          <div
            className={`icon ${mic} ${
              talking && 'talking'
            } ${userThatIsSharing}`}
          >
            {returnMicIcon(mic)}
          </div>
        </div>
        {handsUp && (
          <div className="users-layout-align-handsup-box">
            <div className={`hands-up ${userThatIsSharing}`}>
              {returnHandsUpIcon()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserLayout;
