export enum Events {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  UNREGISTERED = 'UNREGISTERED',
  MAKE_CALL = 'MAKE_CALL',
  RECEIVED_CALL = 'RECEIVED_CALL',
  CALL_ON_GOING = 'CALL_ON_GOING',
  CALL_HANGUP = 'CALL_HANGUP',
  MUTE_MIC = 'MUTE_MIC',
  MUTE_CAM = 'MUTE_CAM',
  HOLD_CALL = 'HOLD_CALL',
  ACCEPTED_CALL = 'ACCEPTED_CALL',
}
export interface SubscriptionCallBack {
  (event: Events, data: any): void;
}
