export function isGroupEvent(stanza: any) {
  return (
    stanza.getChild('subject') &&
    stanza.getChild('subject').children[0] === 'KEY_ROOM_GENERAL'
  );
}
export function isNewGroup(stanza: any) {
  return (
    stanza.getChild('x') &&
    stanza.getChild('x').attrs.xmlns === 'jabber:x:conference' &&
    stanza.getChild('x').attrs.jid
  );
}
export function getEventId(stanza: any) {
  const eventId: string = stanza
    .getChild('extraParams')
    ?.getChild('key_room_event')?.children[0];
  return parseInt(eventId);
}
export function getEventBody(stanza: any) {
  const eventBody: string = stanza
    .getChild('extraParams')
    ?.getChild('key_room_event_body')?.children[0];
  return eventBody;
}
export function isTextMessage(stanza: any) {
  return (
    stanza.getChild('body') !== undefined &&
    (!stanza.getChild('x') ||
      stanza.getChild('x').attrs.xmlns !== 'jabber:x:oob')
  );
}
export function isFileMessage(stanza: any) {
  return (
    stanza.getChild('x') && stanza.getChild('x').attrs.xmlns === 'jabber:x:oob'
  );
}
export function isReceived(stanza: any) {
  return !!stanza.getChild('received');
}
export function isDisplayed(stanza: any) {
  return !!stanza.getChild('displayed');
}
export function isComposing(stanza: any) {
  return !!stanza.getChild('composing');
}
export function isActive(stanza: any) {
  return !!stanza.getChild('active');
}
export function getReceivedMessageId(stanza: any) {
  return stanza.getChild('received').attrs.id;
}
export function getDisplayedMessageId(stanza: any) {
  return stanza.getChild('displayed').attrs.id;
}
export function getMessage(stanza: any) {
  if (isTextMessage(stanza)) {
    return stanza.getChild('body').children[0];
  }
}
export function getFileUrl(stanza: any) {
  return stanza.getChild('body').children[0];
}
export function getMessageId(stanza: any) {
  return stanza.attrs.id;
}
export function getFrom(stanza: any): string {
  if (stanza.attrs.from.includes('@conference.')) {
    return stanza.attrs.from;
  }
  return stanza.attrs.from.split('/')[0];
}
export function getTo(stanza: any) {
  return stanza.attrs.to.split('/')[0];
}
export function getMessageType(stanza: any) {
  return stanza.attrs.type;
}
export function getMessagFileUrl(stanza: any) {
  return stanza.getChild('body').children[0];
}
export function getReplyTo(stanza: any) {
  if (stanza.getChild('extraParams')?.getChild('reply_to')?.children)
    return stanza.getChild('extraParams').getChild('reply_to').children[0];
  return undefined;
}
export function getReplyMsg(stanza: any) {
  if (stanza.getChild('extraParams')?.getChild('reply_msg')?.children)
    return stanza.getChild('extraParams').getChild('reply_msg').children[0];
  return undefined;
}
export function getReplyMsgId(stanza: any) {
  if (stanza.getChild('extraParams')?.getChild('reply_msg_id')?.children)
    return stanza.getChild('extraParams').getChild('reply_msg_id').children[0];
  return undefined;
}
