import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import { IoHandRightOutline } from 'react-icons/io5';
import {
  RiPhoneLine,
  RiCameraLine,
  RiMicLine,
  RiMessage3Line,
  RiGroupLine,
  RiCameraOffLine,
  RiMicOffLine,
  RiLayoutMasonryLine,
  RiEyeLine,
} from 'react-icons/ri/index';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import { IParticipantItem } from '../../interfaces/participant';
import { RequestSpeakEvent } from '../../services/SquadEventsCommunicator';
import { useAuth } from '../../hooks/auth';
import './styles.scss';
import { parseName } from '../../helpers/parseName';

interface ILayoutsValue {
  value: string;
  label: string;
}

interface IProps {
  openParticipants(): void;
  openChat(): void;
  endCall(): void;
  openMyVideo(): void;
  muteCam(): void;
  unMuteCam(): void;
  screenShare(): void;
  video: boolean;
  muteMic(): void;
  unMuteMic(): void;
  mic: boolean;
  newMessage: boolean;
  inScreenShare: boolean;
  changeLayout(layout: string): void;
  participants: IParticipantItem[];
  usersWhoseWantToSpeak: RequestSpeakEvent[];
  setUsersWhoseWantToSpeak(value: RequestSpeakEvent[]): void;
  requestSpeak(): void;
  isSquad: boolean;
  newMessages: boolean;
  setNewMessages(value: boolean): void;
  showModalDevicesConfig: boolean;
  setShowModalDevicesConfig(value: boolean): void;
}

const VideoCallActions: React.FC<IProps> = props => {
  const {
    openParticipants,
    endCall,
    openChat,
    openMyVideo,
    muteCam,
    unMuteCam,
    muteMic,
    unMuteMic,
    video,
    mic,
    screenShare,
    inScreenShare,
    changeLayout,
    usersWhoseWantToSpeak,
    requestSpeak,
    setUsersWhoseWantToSpeak,
    isSquad,
    newMessages,
    setNewMessages,
  } = props;
  const { user } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerLayoutRef = useRef<HTMLDivElement>(null);
  const [show] = useState(true);
  const [someoneAskedToSpeak, setSomeoneAskedToSpeak] = useState<boolean>(
    false,
  );
  const [showLayouts, setShowLayouts] = useState(false);
  const [
    showHandsUpParticipants,
    setShowHandsUpParticipants,
  ] = useState<boolean>(false);
  const [layouts] = useState<ILayoutsValue[]>([
    {
      value: 'group:grid',
      label: 'Distribuir em grade',
    },
    {
      value: 'group:grid-zoom',
      label: 'Destacar usuários',
    },
    {
      value: '1up_top_left+9',
      label: 'Destacar quem fala com os demais ao redor',
    },
    {
      value: 'overlaps',
      label: 'Destacar quem fala com os demais abaixo',
    },
    {
      value: 'presenter-overlap-small-bot-right',
      label: 'Destacar apresentação',
    },
  ]);

  const handleClick = useCallback(
    e => {
      if (!containerLayoutRef.current?.contains(e.target)) {
        if (showLayouts) {
          setShowLayouts(false);
        }
      }
    },
    [showLayouts],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    setUsersWhoseWantToSpeak([]);
  }, [setUsersWhoseWantToSpeak]);

  const toggleHandsUpParticipants = () => {
    setShowHandsUpParticipants(!showHandsUpParticipants);
  };
  const readMessages = () => {
    setNewMessages(false);
  };

  useEffect(() => setSomeoneAskedToSpeak(!!usersWhoseWantToSpeak[0]), [
    usersWhoseWantToSpeak,
    setSomeoneAskedToSpeak,
    user,
  ]);

  return (
    <>
      <div
        ref={containerRef}
        className={`video-actions-container-bottom ${show && 'show'}`}
      >
        <div className="firstContainer">
          <button
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
            type="button"
            style={{
              backgroundColor: 'transparent',
              color: 'transparent',
            }}
            className="button-call-action settings"
          >
            .
          </button>
        </div>
        <div className="secondContainer">
          <button
            type="button"
            className="button-call-action end-call"
            onClick={endCall}
          >
            <RiPhoneLine />
          </button>
          <button
            type="button"
            className={`button-call-action ${!video && 'active'} cameraIcon`}
            onClick={debounce(
              () => {
                if (video) {
                  muteCam();
                } else {
                  unMuteCam();
                }
              },
              500,
              {
                leading: true,
                trailing: false,
              },
            )}
          >
            {video ? <RiCameraLine /> : <RiCameraOffLine />}
          </button>
          {/* <button type="button" className="buttonDropdown microphone">
            <RiArrowDropDownLine />
          </button> */}
          <button
            type="button"
            className={`button-call-action ${!mic && 'active'}`}
            onClick={debounce(
              () => {
                if (mic) {
                  muteMic();
                } else {
                  unMuteMic();
                }
              },
              500,
              {
                leading: true,
                trailing: false,
              },
            )}
          >
            {mic ? <RiMicLine /> : <RiMicOffLine />}
          </button>

          <button
            type="button"
            className="button-call-action"
            onClick={openMyVideo}
          >
            <RiEyeLine />
          </button>
          <button
            type="button"
            className={`button-call-action ${inScreenShare && 'active'}`}
            onClick={screenShare}
          >
            {inScreenShare ? <MdStopScreenShare /> : <MdScreenShare />}
          </button>
          <button
            type="button"
            className="button-call-action dropdown-button"
            onClick={() => setShowLayouts(!showLayouts)}
          >
            <RiLayoutMasonryLine />
            {showLayouts && (
              <div
                className="dropdown-layouts layouts"
                ref={containerLayoutRef}
              >
                <ul>
                  {layouts.map(item => (
                    <li
                      key={item.value}
                      className="layout-item"
                      onClick={() => changeLayout(item.value)}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </button>
        </div>
        <div className="thirdContainer">
          <motion.button
            transition={{
              duration: 0.7,
              delay: 0.7,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
            initial={{
              backgroundColor: '#FFF',
              color: '#999',
            }}
            animate={{
              backgroundColor: '#A3D241',
              color: '#FFF',
            }}
            type="button"
            color="none"
            onClick={() => {
              toggleHandsUpParticipants();
            }}
            className={`button-call-hands-up-green-toggle ${
              !someoneAskedToSpeak ? 'd-none' : ''
            }`}
          >
            <IoHandRightOutline />
          </motion.button>

          <button
            type="button"
            className={`button-call-action ${someoneAskedToSpeak && 'd-none'}`}
            onClick={() => {
              toggleHandsUpParticipants();
            }}
          >
            <IoHandRightOutline />
          </button>
          {showHandsUpParticipants && (
            <div className={`dropdown-layouts ${isSquad ? 'squad' : ''}`}>
              <ul style={{ textAlign: 'center' }}>
                <li
                  className="layout-item hands-up"
                  onClick={() => requestSpeak()}
                >
                  {usersWhoseWantToSpeak.find(spk => spk.userName === user.name)
                    ? 'Abaixar a mão'
                    : 'Levantar a mão'}
                </li>
                <hr />
                {usersWhoseWantToSpeak.map(participant => (
                  <li key={participant.userId} className="layout-item users">
                    <div
                      className={`d-flex flex-row align-items-center justify-content-center ${'green'}`}
                    >
                      <IoHandRightOutline
                        size={20}
                        style={{ marginRight: '5px', marginTop: '2px' }}
                      />
                      {parseName(participant.userName)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!isSquad && (
            <>
              <button
                type="button"
                className="button-call-action msg-button"
                onClick={() => {
                  openChat();
                  readMessages();
                }}
              >
                <RiMessage3Line />
                <div className={`${!newMessages && 'd-none'} newMessagePing`} />
              </button>
              <button
                type="button"
                className="button-call-action"
                onClick={openParticipants}
              >
                <RiGroupLine />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoCallActions;
