import React, { useCallback, useEffect, useRef, useState } from 'react';

import './styles.scss';

interface IUserVideo {
  openVideo: boolean;
}
const UserVideo: React.FC<IUserVideo> = ({ openVideo }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream>();

  const getUserVideoStream = useCallback(async () => {
    if (openVideo && !mediaStream && videoRef.current) {
      const { mediaDevices } = navigator;
      const stream = await mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: 'user' },
      });
      setMediaStream(stream);
      videoRef.current.srcObject = stream;
    } else if (mediaStream && !openVideo) {
      mediaStream.getTracks().forEach(t => t.stop());
      setMediaStream(undefined);
    }
  }, [mediaStream, openVideo, videoRef]);

  useEffect(() => {
    getUserVideoStream();
  }, [getUserVideoStream, openVideo]);

  return (
    <div className="user-video">
      <video
        ref={videoRef}
        autoPlay
        style={{ display: openVideo ? 'block' : 'none' }}
      />
    </div>
  );
};

export default UserVideo;
