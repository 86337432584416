import React, { useCallback, useEffect, useRef } from 'react';
import {
  RiArrowDropDownLine,
  RiMicLine,
  RiMicOffLine,
  RiVolumeUpLine,
} from 'react-icons/ri';

import './styles.scss';

interface IProps {
  mic: boolean;
  changeMic(): void;
  showMicConfig: boolean;
  micInputDevices: MediaDeviceInfo[];
  micOutputDevices: MediaDeviceInfo[];
  setShowMicConfig(): void;
  changeSpeakerDevice(deviceId: string): void;
  changeAudioDevice(deviceId: string): void;
  closeConfig(): void;
  currentAudio: string;
  currentSpeaker: string;
}

const MeetConfigMicButton: React.FC<IProps> = props => {
  const {
    changeMic,
    showMicConfig,
    mic,
    micInputDevices,
    micOutputDevices,
    setShowMicConfig,
    changeAudioDevice,
    changeSpeakerDevice,
    currentAudio,
    currentSpeaker,
    closeConfig,
  } = props;
  const containerMicRef = useRef<HTMLDivElement>(null);

  const onChangeAudioDevice = useCallback(
    (deviceId: string) => {
      changeAudioDevice(deviceId);
    },
    [changeAudioDevice],
  );

  const onChangeSpeakerDevice = useCallback(
    (deviceId: string) => {
      changeSpeakerDevice(deviceId);
    },
    [changeSpeakerDevice],
  );

  const handleClick = useCallback(
    e => {
      if (!containerMicRef.current?.contains(e.target)) {
        if (showMicConfig) {
          closeConfig();
        }
      }
    },
    [showMicConfig, closeConfig],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <div className="meet-config-button" ref={containerMicRef}>
      <span
        className={`icon-button ${!mic && 'no-config'}`}
        onClick={changeMic}
      >
        {mic ? <RiMicLine size={22} /> : <RiMicOffLine size={22} />}
      </span>
      {showMicConfig && (
        <div className="dropdown-mic">
          <ul>
            <li className="item-section">
              <RiMicLine />
              Microphones
            </li>
            {micInputDevices.map(input => (
              <li
                key={input.deviceId}
                className={`audio-item ${
                  input.deviceId === currentAudio && 'active'
                }`}
                onClick={() => onChangeAudioDevice(input.deviceId)}
              >
                {input.label}
              </li>
            ))}
            <li className="item-section">
              <RiVolumeUpLine />
              Speakers
            </li>
            {micOutputDevices.map(input => (
              <li
                key={input.deviceId}
                className={`audio-item ${
                  input.deviceId === currentSpeaker && 'active'
                }`}
                onClick={() => onChangeSpeakerDevice(input.deviceId)}
              >
                {input.label}
              </li>
            ))}
          </ul>
        </div>
      )}

      <span className="actions-button" onClick={setShowMicConfig}>
        <RiArrowDropDownLine />
      </span>
    </div>
  );
};

export default MeetConfigMicButton;
