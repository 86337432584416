/* eslint-disable no-unused-expressions */
import socketIoClient, { Socket } from 'socket.io-client';

export const commands = {
  tmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} tmute ${memberId}`,
  tvmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} tvmute ${memberId}`,
  mute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} mute ${memberId}`,
  vmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} vmute ${memberId}`,
  unmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} unmute ${memberId}`,
  unvmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} unvmute ${memberId}`,
  kick: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} kick ${memberId}`,
  vidFloor: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} vid-floor ${memberId} force`,
  muteNonMod: (conferenceId: string): string =>
    `conference ${conferenceId} mute non_moderator`,
  vmuteNonMod: (conferenceId: string): string =>
    `conference ${conferenceId} vmute non_moderator`,
  listLayouts: (conferenceId: string): string =>
    `conference ${conferenceId} vid-layout list`,
  setLayout: (conferenceId: string, layoutName: string): string =>
    `conference ${conferenceId} vid-layout ${layoutName}`,
};

export type Commands =
  | 'tmute'
  | 'tvmute'
  | 'mute'
  | 'vmute'
  | 'unmute'
  | 'unvmute'
  | 'muteNonMod'
  | 'vmuteNonMod'
  | 'kick'
  | 'vidFloor'
  | 'listLayouts'
  | 'setLayout'
  | 'requestSpeak';

export enum ConferenceEvents {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  REQUEST_SPEAK = 'REQUEST_SPEAK',
}

export interface ConferenceEvent {
  sip_server_ip: string;
  Caller_Name: string;
  members: ConferenceEventData[];
}
export interface RequestSpeakEvent {
  conferenceId: string;
  command: string;
  userId: string;
  userName: string;
}
export interface ConferenceEventData {
  Caller_Name: string;
  Conference_Name: string;
  Caller_Caller_ID_Name: string;
  Video: boolean;
  Hear: boolean;
  See: boolean;
  Speak: boolean;
  Talking: boolean;
  Floor: boolean;
  'Mute-Detect': boolean;
  Hold: boolean;
  'Member-ID': number;
  'Member-Type': 'moderator' | 'participant';
  'Member-Ghost': boolean;
  Action:
    | 'add-member'
    | 'floor-change'
    | 'video-floor-change'
    | 'mute-member'
    | 'unmute-member'
    | 'vmute-member'
    | 'unvmute-member'
    | 'start-talking'
    | 'stop-talking'
    | 'del-member';
}

interface EventCallback {
  (event: ConferenceEvents, data: ConferenceEvent | RequestSpeakEvent[]): void;
}

export class SquadEvenstCommunicator {
  public currentSocket: Socket | undefined;

  private eventCallback: EventCallback | undefined;

  public connect(conferenceId: string, eventCallback: EventCallback): void {
    this.eventCallback = eventCallback;
    this.currentSocket = socketIoClient.io('https://app.squad.us/');
    this.currentSocket.on('connect', () => {
      this.currentSocket?.emit('enterRoom', conferenceId.split('@')[0]);
    });
    this.currentSocket.on('update', (data: string | ConferenceEvent) => {
      data = typeof data === 'string' ? JSON.parse(data) : data;
      this.eventCallback &&
        this.eventCallback(ConferenceEvents.UPDATE, data as ConferenceEvent);
    });
    this.currentSocket.on(
      'requestSpeak',
      (data: string | RequestSpeakEvent[]) => {
        data = typeof data === 'string' ? JSON.parse(data) : data;
        this.eventCallback &&
          this.eventCallback(
            ConferenceEvents.REQUEST_SPEAK,
            data as RequestSpeakEvent[],
          );
      },
    );
  }

  public updateEventCallback = (eventCB: EventCallback): void => {
    this.eventCallback = eventCB;
  };

  public sendCommand = (
    conferenceId: string,
    command: Commands,
    extraParam?: string,
  ): void => {
    if (command !== 'requestSpeak') {
      const mountCommand = commands[command];
      let commandStr;
      if (
        ['tmute', 'tvmute', 'kick', 'vidFloor', 'setLayout'].includes(
          command,
        ) &&
        extraParam
      ) {
        commandStr = mountCommand(conferenceId, extraParam as never);
      } else {
        commandStr = mountCommand(conferenceId, null as never);
      }
      this.currentSocket?.emit('update', commandStr);
    } else {
      this.currentSocket?.emit('update', {
        conferenceId,
        command: 'requestSpeak',
        userId: extraParam,
        userName: extraParam,
      });
    }
  };

  public disconnect = (): void => {
    this.currentSocket?.disconnect();
    this.currentSocket?.close();
    this.currentSocket = undefined;
  };
}
