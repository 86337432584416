import React, { useCallback, useEffect, useRef } from 'react';
import {
  RiArrowDropDownLine,
  RiCameraLine,
  RiCameraOffLine,
} from 'react-icons/ri';
import Webcam from 'react-webcam';

import './styles.scss';

interface IProps {
  video: boolean;
  changeVideo(): void;
  showVideoConfig: boolean;
  devices: MediaDeviceInfo[];
  setShowVideoConfig(): void;
  changeVideoDevice(deviceId: string): void;
}

const MeetConfigVideoButton: React.FC<IProps> = props => {
  const {
    changeVideo,
    showVideoConfig,
    video,
    devices,
    setShowVideoConfig,
    changeVideoDevice,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const onChangeVideoDevice = useCallback(
    (deviceId: string) => {
      changeVideoDevice(deviceId);
      setShowVideoConfig();
    },
    [changeVideoDevice, setShowVideoConfig],
  );

  const handleClick = useCallback(
    e => {
      if (!containerRef.current?.contains(e.target)) {
        if (showVideoConfig) {
          setShowVideoConfig();
        }
      }
    },
    [setShowVideoConfig, showVideoConfig],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <div className="meet-config-button" ref={containerRef}>
      <span
        className={`icon-button meet-config-cam ${!video && 'no-config'}`}
        onClick={changeVideo}
      >
        {video ? <RiCameraLine size={22} /> : <RiCameraOffLine size={22} />}
      </span>
      {showVideoConfig && (
        <div className="dropdown-videocam">
          {devices.map(device => (
            <div
              key={device.deviceId}
              className="videocam-item"
              onClick={() => onChangeVideoDevice(device.deviceId)}
            >
              <Webcam
                width={180}
                height={100}
                audio={false}
                videoConstraints={{
                  deviceId: device.deviceId,
                  height: 720,
                  width: 1280,
                }}
              />
              <span className="device-title">{device.label}</span>
            </div>
          ))}
        </div>
      )}
      <span className="actions-button" onClick={setShowVideoConfig}>
        <RiArrowDropDownLine />
      </span>
    </div>
  );
};

export default MeetConfigVideoButton;
