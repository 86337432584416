import { client, xml, XmppClient } from '@xmpp/client';
import { v4 } from 'uuid';
import PresenceStatus from '../../enums/presenceStatus';
import {
  ActiveCallback,
  ChatType,
  ComposingCallback,
  ConnectionOptions,
  DisplayedCallback,
  ErrorCallback,
  EventCallback,
  Events,
  FileMessage,
  Message,
  MessageCallback,
  OfflineCallback,
  OnlineCallback,
  Presence,
  PresenceCallback,
  ReceivedCallback,
  SendImageCallback,
  SendingFile,
  SendMessageCallback,
  StanzaCallback,
} from './types/types';
import {
  getFrom,
  getMessage,
  isTextMessage,
  getTo,
  getMessageId,
  isFileMessage,
  getMessageType,
  getMessagFileUrl,
  getReplyTo,
  getReplyMsg,
  getReplyMsgId,
  isReceived,
  isDisplayed,
  getReceivedMessageId,
  getDisplayedMessageId,
  isComposing,
  isActive,
  isGroupEvent,
  getEventId,
  isNewGroup,
  getEventBody,
} from './util/stanzaUtils';

const events = require('events');

export default interface Chat {
  service: string;
  domain?: string;
  resource: string;
  username: string;
  password?: string;
  client: XmppClient;
  status: string;
  filesQueue: Map<string, SendingFile>;
  presences: Map<string, Presence>;
  connect(): Promise<any>;
  on(event: Events.MESSAGE, cb: MessageCallback): this;
  on(event: Events.PRESENCE, cb: PresenceCallback): this;
  on(event: Events.ONLINE, cb: OnlineCallback): this;
  on(event: Events.OFFLINE, cb: OfflineCallback): this;
  on(event: Events.ERROR, cb: ErrorCallback): this;
  on(event: Events.STANZA, cb: StanzaCallback): this;
  on(event: Events.RECEIVED, cb: ReceivedCallback): this;
  on(event: Events.DISPLAYED, cb: DisplayedCallback): this;
  on(event: Events.COMPOSING, cb: ComposingCallback): this;
  on(event: Events.ACTIVE, cb: ActiveCallback): this;
  on(event: Events.JOIN_ROOM, cb: EventCallback): this;
  on(event: Events.SEND_EVENT, cb: EventCallback): this;
  on(event: Events.RECONNECTING, cb: EventCallback): this;
  on(event: Events.RECONNECTED, cb: EventCallback): this;
  getPresence(from: string): Presence | null;
  sendMessage(
    to: string,
    chatType: ChatType,
    message: string,
    cb: SendMessageCallback,
  ): void;
  sendFile(
    to: string,
    chatType: ChatType,
    file: File,
    callback: SendImageCallback,
  ): void;
  getDomain(): string;
  joinRoom(to: string): void;
}
export default class Chat extends events.EventEmitter {
  constructor({
    service,
    domain,
    resource,
    username,
    password,
  }: ConnectionOptions) {
    super();
    Object.setPrototypeOf(this, Chat.prototype);
    this.service = service;
    this.domain = domain;
    this.resource = resource;
    this.username = username;
    this.password = password;
    this.client = this.instanceMaker({
      service,
      domain,
      resource,
      username,
      password,
    });
    this.status = this.client.status;
    this.connect = this.client.start;
    this.filesQueue = new Map<string, SendingFile>();
    this.presences = new Map<string, Presence>();
    this.discoItems = new Map<string, string>();
  }

  getJid = () => {
    return `${this.username}@${this.getDomain()}`;
  };

  getDomain = () => {
    return (
      this.domain ||
      this.service.replace('wss://', '').replace('ws://', '').split('/')[0]
    );
  };

  instanceMaker = ({
    service,
    domain,
    resource,
    username,
    password,
  }: ConnectionOptions) => {
    const xmpp = client({
      service,
      domain,
      resource,
      username,
      password,
    });
    xmpp.on('status', this.onStatus);
    xmpp.on('stanza', this.onStanza);
    xmpp.on('online', this.onOnline);
    xmpp.on('error', this.onError);
    xmpp.on('offline', this.onOffline);
    xmpp.reconnect.on('reconnecting', this.onReconnecting);
    xmpp.reconnect.on('reconnected', this.onReconnected);
    return xmpp;
  };

  onReconnecting = () => {
    // console.log("reconnecting");
    this.emit('reconnecting');
  };

  onReconnected = () => {
    // console.log("reconnected");
    this.emit('reconnected');
  };

  onStanza = (stanza: any) => {
    this.emit('stanza', stanza);
    if (stanza.is('message')) this.onMessage(stanza);
    else if (stanza.is('presence')) this.onPresence(stanza);
    else if (stanza.is('iq')) this.onIQ(stanza);
    else return;
  };

  onOnline = (__: any) => {
    this.discoItensId = v4();
    // this.sendEnableCarbon();
    this.sendServiceDiscoveryRequest(this.discoItensId);
    this.client.send(xml('presence'));
    this.emit('online');
  };

  onOffline = () => {
    this.emit('offline');
  };

  onError = (e: any) => {
    this.emit('error', e);
  };

  onStatus = (status: string) => {
    this.emit('status', status);
  };

  onMessage = (stanza: any) => {
    if (isGroupEvent(stanza)) {
      if (isNewGroup(stanza)) {
        const eventToNewGroup: Message = {
          to: getTo(stanza),
          from: getFrom(stanza),
          id: v4(),
          eventId: 1,
          message: '',
          reply_msg: undefined,
          sent_at: new Date().toISOString(),
          type: 'groupchat',
          reply_msg_id: undefined,
          reply_to: undefined,
        };
        this.emit(Events.SEND_EVENT, eventToNewGroup);
      } else {
        const event: Message = {
          id: getMessageId(stanza),
          to: getTo(stanza),
          from: getFrom(stanza),
          message: '',
          type: 'groupchat',
          sent_at: new Date().toISOString(),
          reply_to: undefined,
          reply_msg: undefined,
          reply_msg_id: undefined,
          eventId: getEventId(stanza),
          eventBody: getEventBody(stanza),
        };
        this.emit(Events.SEND_EVENT, event);
      }
    } else if (isTextMessage(stanza)) {
      const message: Message = {
        id: getMessageId(stanza),
        to: getTo(stanza),
        from: getFrom(stanza),
        message: getMessage(stanza),
        type: getMessageType(stanza),
        sent_at: new Date().toISOString(),
        reply_to: getReplyTo(stanza),
        reply_msg: getReplyMsg(stanza),
        reply_msg_id: getReplyMsgId(stanza),
      };
      this.sendReceipts(stanza);
      this.emit(Events.MESSAGE, message);
    } else if (isFileMessage(stanza)) {
      const fileMessage: FileMessage = {
        id: getMessageId(stanza),
        to: getTo(stanza),
        from: getFrom(stanza),
        message: '',
        type: getMessageType(stanza),
        sent_at: new Date().toISOString(),
        reply_to: getReplyTo(stanza),
        reply_msg: getReplyMsg(stanza),
        reply_msg_id: getReplyMsgId(stanza),
        fileUrl: getMessagFileUrl(stanza),
      };
      this.sendReceipts(stanza);
      this.emit(Events.MESSAGE, fileMessage);
    } else if (isReceived(stanza)) {
      const received = {
        id: getReceivedMessageId(stanza),
      };
      this.emit(Events.RECEIVED, received);
    } else if (isDisplayed(stanza)) {
      const displayed = {
        id: getDisplayedMessageId(stanza),
      };
      this.emit(Events.DISPLAYED, displayed);
    } else if (isComposing(stanza)) {
      this.emit(Events.COMPOSING, getFrom(stanza));
    } else if (isActive(stanza)) {
      this.emit(Events.ACTIVE, getFrom(stanza));
    }
  };

  onPresence = (stanza: any) => {
    const presence: Presence = {
      id: v4(),
      from: stanza.attrs.from,
      time: new Date().toISOString(),
      status: stanza.getChild('show')
        ? stanza.getChild('show').children[0]
        : stanza.attrs.type || 'online',
    };
    const otherPresence = Array.from(this.presences.values()).find(
      pres =>
        pres.from.split('/')[0] === presence.from.split('/')[0] &&
        pres.from !== presence.from,
    );
    if (otherPresence && presence.status === 'offline') {
      this.emit('presence', otherPresence);
    } else {
      this.emit('presence', presence);
    }
    this.presences.set(stanza.attrs.from, presence);
  };

  onIQ = (stanza: any) => {
    if (stanza.attrs.type === 'result') {
      if (stanza.attrs.id) {
        const file = this.filesQueue.get(stanza.attrs.id);
        if (file) {
          if (stanza.attrs.id === file.firstStepId) {
            this.sendFileSecondStep(stanza);
          } else if (stanza.attrs.id === file.secondStepId) {
            this.sendFileThirdStep(stanza, file);
          }
        } else if (stanza.attrs.id === this.discoItensId) {
          stanza.children[0].children.forEach((element: any) => {
            const key = element.attrs.jid.split('.')[0];
            this.discoItems.set(key, element.attrs.jid);
          });
        }
      }
    }
  };

  getPresence = (from: string) => {
    const presence = this.presences.get(from);
    if (presence) return presence;
    return null;
  };

  sendEnableCarbon = () => {
    const stanza = xml(
      'iq',
      { from: `${this.getJid()}/${this.resource}`, type: 'set', id: 'enable1' },
      xml('enable', { xmlns: 'urn:xmpp:carbons:2' }),
    );
    this.client.send(stanza);
  };

  sendPresence = (status: string, to: string | undefined = undefined) => {
    const show = xml('show', {}, status);
    if (status !== 'online') this.client.send(xml('presence', { to }, show));
    else this.client.send(xml('presence'));
  };

  leaveGroup = (
    to: string,
    type: PresenceStatus = PresenceStatus.UNAVAILABLE,
  ) => {
    this.client.send(xml('presence', { to, type }));
  };

  sendMessage = (
    to: string,
    chatType: ChatType,
    message: string,
    callback: SendMessageCallback,
  ) => {
    const msgId = v4();
    this.client
      .send(
        xml(
          'message',
          {
            id: msgId,
            type: chatType,
            to,
          },
          xml('body', {}, message),
          xml('request', { xmlns: 'urn:xmpp:receipts' }),
        ),
      )
      .then(() => {
        callback(msgId);
      });
  };

  sendFile = (
    to: string,
    chatType: ChatType,
    file: File,
    callback: SendImageCallback,
  ) => {
    const fileId = v4();
    this.filesQueue.set(fileId, {
      firstStepId: fileId,
      file,
      to,
      chatType,
      callback,
    });
    this.sendFileFirstStep(fileId);
  };

  sendImageMessage = (
    url: string,
    to: string,
    chatType: ChatType,
    callback: SendImageCallback,
  ) => {
    const msgId = v4();
    const messagePacket = xml(
      'message',
      {
        id: msgId,
        type: chatType,
        to,
      },
      xml('body', {}, url),
      xml('request', { xmlns: 'urn:xmpp:receipts' }),
      xml('x', { xmlns: 'jabber:x:oob' }, xml('url', {}, url)),
    );
    if (this.client) {
      callback({ url, msgId });
      this.client.send(messagePacket);
    }
  };

  sendFileFirstStep = (fileId: string) => {
    const uploadJid = this.discoItems.get('upload');
    if (uploadJid) {
      this.sendServiceDiscoveryRequestToUpload(uploadJid, fileId);
    }
  };

  sendFileSecondStep = (stanza: any) => {
    if (stanza.children.length > 0) {
      const secondStepId = v4();
      const file = this.filesQueue.get(stanza.attrs.id);
      if (file) {
        this.filesQueue.set(secondStepId, {
          firstStepId: file.firstStepId,
          secondStepId,
          file: file.file,
          to: file.to,
          chatType: file.chatType,
          callback: file.callback,
        });
        this.filesQueue.delete(stanza.attrs.id);
      }
      console.log(
        'upload request xmlns',
        stanza.children[0].children[2].attrs.var,
      );
      this.sendRequestSlotOnUpload(stanza.attrs.from, secondStepId);
    }
  };

  sendFileThirdStep = (stanza: any, file: SendingFile) => {
    let getUrl = '';
    let putUrl = '';
    stanza.children[0].children.forEach((element: any) => {
      if (element.is('get')) {
        getUrl = element.attrs.url;
      } else if (element.is('put')) {
        putUrl = element.attrs.url;
      }
    });
    if (file?.secondStepId) this.uploadFile(putUrl, getUrl, file.secondStepId);
  };

  sendServiceDiscoveryRequest = (fileId: string) => {
    const serviceDiscoveryRequest = xml(
      'iq',
      {
        type: 'get',
        to: this.getDomain(),
        id: fileId,
      },
      xml('query', { xmlns: 'http://jabber.org/protocol/disco#items' }),
    );
    if (this.client) {
      this.client.send(serviceDiscoveryRequest);
    }
  };

  sendServiceDiscoveryRequestToUpload = (
    uploadJid: string,
    uploadId: string,
  ) => {
    console.log('sendServiceDiscoveryRequestToUpload', uploadJid);
    const serviceDiscoveryRequest = xml(
      'iq',
      {
        type: 'get',
        to: uploadJid,
        id: uploadId,
      },
      xml('query', { xmlns: 'http://jabber.org/protocol/disco#info' }),
    );
    if (this.client) {
      this.client.send(serviceDiscoveryRequest);
    }
  };

  sendRequestSlotOnUpload = (uploadJid: string, thirdStepId: string) => {
    console.log('sendRequestSlotOnUpload', uploadJid);
    const file = this.filesQueue.get(thirdStepId)?.file;
    const serviceDiscoveryRequest = xml(
      'iq',
      {
        type: 'get',
        to: uploadJid,
        id: thirdStepId,
      },
      xml('request', {
        xmlns: 'urn:xmpp:http:upload:0',
        filename: file?.name,
        size: file?.size,
        'content-type': file?.type,
      }),
    );
    if (this.client) {
      this.client.send(serviceDiscoveryRequest);
    }
  };

  uploadFile = (putUrl: string, getUrl: string, id: string) => {
    const xhr = new XMLHttpRequest();
    const file = this.filesQueue.get(id);
    if (file) {
      xhr.onerror = () => {
        if (xhr.responseText) {
          file.callback(xhr.responseText, true);
          console.log('upload error');
        }
      };
      xhr.onreadystatechange = e => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          console.log('file upload response', e, xhr.status);
          if (xhr.status === 200 || xhr.status === 201) {
            console.log('file upload response success');
            this.sendImageMessage(
              getUrl,
              file.to,
              file.chatType,
              file?.callback,
            );
            this.filesQueue.delete(id);
          }
        }
      };
      xhr.upload.addEventListener(
        'progress',
        evt => {
          console.log('progress', evt);
          // if (file) file.file.size = evt.total
        },
        false,
      );
      xhr.open('PUT', putUrl, true);
      xhr.setRequestHeader('Content-Type', file.file.type);

      xhr.send(file.file);

      console.log('URL', putUrl);
      console.log('fileSize', file.file.size);
    }
  };

  sendReceipts = (stanza: any) => {
    const message = xml(
      'message',
      {
        from: stanza.attrs.to,
        id: v4(),
        to: stanza.attrs.from.split('/')[0],
      },
      xml('received', { xmlns: 'urn:xmpp:receipts', id: stanza.attrs.id }),
    );
    console.log(message);
    this.client.send(message);
  };

  sendTyping = (to: string) => {
    const composing = xml(
      'message',
      {
        id: v4(),
        to,
        type: 'chat',
      },
      xml('composing', {
        xmlns: 'http://jabber.org/protocol/chatstates',
      }),
    );
    this.client.send(composing);
  };

  sendActive = (to: string) => {
    const active = xml(
      'message',
      {
        id: v4(),
        to,
        type: 'chat',
      },
      xml('active', {
        xmlns: 'http://jabber.org/protocol/chatstates',
      }),
    );
    this.client.send(active);
  };

  replyMsg = (
    to: string,
    chatType: string,
    message: string,
    replyed_sender: string,
    replyed_msg: string,
    replyed_msg_id: string,
    cb: SendMessageCallback,
  ) => {
    const id = v4();
    const reply = xml(
      'message',
      { to, id, type: chatType },
      xml('body', {}, message),
      xml(
        'extraParams',
        {},
        xml('reply_to', {}, replyed_sender),
        xml('reply_msg', {}, replyed_msg),
        xml('reply_msg_id', {}, replyed_msg_id),
      ),
    );
    this.client.send(reply).then(() => {
      cb(id);
    });
  };
  /* 
  unMarkRoomEvent = (
    to: string,
    eventBody: string | undefined = undefined,
    cb: SendMessageCallback
  ) => {
    const id = v4();
    const stanza = xml(
      "message",
      { to, type: "groupchat", id },
      xml("body", {}),
      xml("subject", {}, "KEY_ROOM_GENERAL"),
      xml(
        "extraParams",
        { xmlns: "jabber:client" },
        xml("key_room_event", {}, "9"),
        eventBody ? xml("key_room_event_body", {}, eventBody) : undefined
      )
    );
    this.client.send(stanza).then(() => {
      cb(id);
    });
  }; */

  sendEvent = (
    to: string,
    eventBody: string | undefined = undefined, // jid from user
    nbr: string | undefined = undefined,
    cb: SendMessageCallback = () => {},
  ) => {
    const id = v4();
    const stanza = xml(
      'message',
      { to, type: 'groupchat', id },
      xml('subject', {}, 'KEY_ROOM_GENERAL'),
      xml('body', {}),
      xml(
        'extraParams',
        { xmlns: 'jabber:client' },
        xml('key_room_event', {}, nbr),
        eventBody ? xml('key_room_event_body', {}, eventBody) : undefined,
      ),
    );
    this.client.send(stanza).then(() => {
      cb(id);
    });
  };

  joinRoomEvent = (
    to: string,
    eventBody: string | undefined, // jid of user who was added -> I ADDED
    from: string,
    cb: SendMessageCallback = () => {},
  ) => {
    const id = v4();
    const stanza = xml(
      'message',
      { to, from, type: 'groupchat', id }, // from - UNNECESSARY?
      xml(
        'extraParams',
        {},
        xml('key_room_event', {}, '2'),
        eventBody ? xml('key_room_event_body', {}, eventBody) : undefined,
      ),
      xml('body', {}),
      xml('subject', {}, 'KEY_ROOM_GENERAL'),
    );
    this.client.send(stanza).then(() => {
      cb(id);
    });
  };

  /*
  leaveRoomEvent = (
    to: string,
    from: string,
    cb: SendMessageCallback
  ) => {
    const id = v4();
    const stanza = xml(
      "message", {
        to, from, type: "groupchat", id
      },
      xml("extraParams", {},
        xml("key_room_event", {}, "3")
      ),
      xml("body", {}),
      xml("subject", {}, "KEY_ROOM_GENERAL"),
    )
    this.client.send(stanza).then(() => {
      cb(id);
    });
  };
 */
  forwardMsg = (
    to: string,
    chatType: ChatType,
    forwarded_msg_sender: string,
    forwarded_msg: string,
    forwarded_msg_id: string,
    callback: SendMessageCallback,
  ) => {
    const id = v4();
    const forward = xml(
      'message',
      { to, id, type: chatType },
      xml('body', {}, forwarded_msg),
      xml(
        'extraParams',
        {},
        xml('reply_to', {}, forwarded_msg_sender),
        xml('reply_msg_id', {}, forwarded_msg_id),
      ),
      xml('request', { xmlns: 'urn:xmpp:receipts' }),
    );
    this.client.send(forward).then(() => {
      callback(id);
    });
  };

  joinRoom = (to: string) => {
    const joinStanza = xml(
      'presence',
      {
        to: `${to}/${this.username}`,
        from: `${this.getJid()}/${this.resource}`,
      },
      xml(
        'x',
        { xmlns: 'http://jabber.org/protocol/muc' },
        xml('history', { maxstanzas: '0' }),
      ),
    );
    this.client.send(joinStanza);
  };
}
