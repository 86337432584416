import React, { createContext, useCallback, useContext, useState } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

interface IApiContextState {
  api: AxiosInstance;
  setApiUrl(url: string): void;
}

const ApiContext = createContext<IApiContextState>({} as IApiContextState);

export const ApiProvider: React.FC = ({ children }) => {
  const [url, setUrl] = useState<string>(() => {
    const urlData = localStorage.getItem('@SquadAnnymous:url');

    if (urlData) {
      return urlData;
    }

    return '';
  });
  const [api] = useState<AxiosInstance>(() => {
    const rest = axios.create({
      baseURL: url,
    });

    rest.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers.Authorization === undefined) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          '@DCSAdmin:token',
        )}`;
      }
      return config;
    });
    return rest;
  });

  const setApiUrl = useCallback((api_url: string) => {
    localStorage.setItem('@SquadAnnymous:url', api_url);
    setUrl(api_url);
  }, []);

  return (
    <ApiContext.Provider value={{ api, setApiUrl }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = (): IApiContextState => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};
