/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Invitation,
  Inviter,
  RegistererState,
  Session,
  SessionState,
} from 'sip.js';
import { IConferenceVoiceResponse } from '../../interfaces/conference';
import { camResCheck } from '../utils/camResCheck';
import { Events, SubscriptionCallBack } from './types/types';
import SIP from './voicecommunicator';

export interface IUpdateConstraintsParams {
  useVideo?: boolean;
  videoId?: string;
  audioId?: string;
  speakerId?: string;
}

export interface ISquadVoiceCommunicator {
  sip: SIP;
  nextSubscriptionId?: number;
  subscriptions?: Map<number, SubscriptionCallBack>;
}

export class SquadVoiceCommunicator {
  private sip!: SIP;

  private subscriptions;

  private nextSubscriptionId;

  private voiceConfig!: IConferenceVoiceResponse;

  private constraints: IUpdateConstraintsParams = {};

  private user: string;

  private reinviting?: boolean = false;

  constructor(voiceConfig: IConferenceVoiceResponse, user: string) {
    this.voiceConfig = voiceConfig;
    this.user = user;
    this.nextSubscriptionId = 0;
    this.subscriptions = new Map<number, SubscriptionCallBack>();
    setTimeout(() => {
      this.makeSip();
    }, 2000);
  }

  public makeSip(): void {
    const { user, password, hostname, websocketUrl2 } = this.voiceConfig;

    this.sip = new SIP({
      user,
      password,
      domain: hostname,
      displayName: this.user,
      wsURL: websocketUrl2 || `wss://${hostname}:7443`,
      connectionCB: this.connectionListener.bind(this),
      onConnect: this.onConnect.bind(this),
      onDisconnect: this.onDisconnect,
      onMakeCall: this.onMakeCall.bind(this),
      onReceiveCall: this.onReceiveCall.bind(this),
    });
  }

  public async screenShare(conferenceId: string): Promise<void> {
    this.sip.conferenceScreenShare(conferenceId);
  }

  public endScreenShare(): void {
    this.sip.endScreenShare();
  }

  public connectionListener(data: RegistererState): void {
    switch (data) {
      case RegistererState.Initial:
        break;
      case RegistererState.Registered:
        this.notifyEvent(Events.CONNECTED);
        break;
      case RegistererState.Terminated:
        this.notifyEvent(Events.DISCONNECTED);
        break;
      case RegistererState.Unregistered:
        this.notifyEvent(Events.UNREGISTERED);
        break;
      default:
        break;
    }
  }

  public onConnect(): void {
    this.notifyEvent(Events.CONNECTED);
  }

  public attemptReconnect(): void {
    setTimeout(() => {
      this.sip.userAgent
        ?.reconnect()
        .then(() => {
          this.sip.registerer?.register();
        })
        .catch(() => {
          this.attemptReconnect();
        });
    }, 4000);
  }

  public onDisconnect(): void {
    this.attemptReconnect();
    this.notifyEvent(Events.DISCONNECTED);
  }

  public endCall(callId: string): void {
    this.sip.endCall(callId);
  }

  public getConstraints(): MediaStreamConstraints {
    const newConstraints: MediaStreamConstraints = {
      video:
        this.constraints.useVideo && this.constraints.videoId
          ? camResCheck(this.constraints.videoId) || {
              deviceId: this.constraints.videoId,
            }
          : this.constraints.useVideo || false,
      audio: this.constraints.audioId
        ? {
            deviceId: this.constraints.audioId,
          }
        : true,
    };
    return newConstraints;
  }

  public getSpeakerId(): string | undefined {
    return this.constraints.speakerId;
  }

  public updateConstraintsParams(params: IUpdateConstraintsParams): void {
    this.constraints = { ...this.constraints, ...params };
  }

  public acceptCall(call: Session, useVideo: boolean): void {
    const acceptedCall = { callId: call.id, useVideo };
    this.updateConstraintsParams({ useVideo });
    (call as Invitation).accept({
      sessionDescriptionHandlerOptions: {
        constraints: this.getConstraints(),
      },
    });
    this.notifyEvent(Events.ACCEPTED_CALL, acceptedCall);
  }

  public makeCall(
    number: string,
    constraints: IUpdateConstraintsParams = this.constraints,
  ): void {
    this.updateConstraintsParams(constraints);
    this.sip.invite(number, this.getConstraints());
  }

  public onMakeCall(state: SessionState, inviter: Inviter): void {
    switch (state) {
      case SessionState.Initial:
        this.notifyEvent(Events.MAKE_CALL, inviter);
        break;
      case SessionState.Establishing:
        break;
      case SessionState.Established:
        const { receivingVideo, tagId } =
          this.sip.setupRemoteMedia(inviter, this.getSpeakerId()) || {};
        this.notifyEvent(Events.CALL_ON_GOING, {
          inviter,
          receivingVideo,
          tagId,
        });
        break;
      case SessionState.Terminating:
      // fall through
      case SessionState.Terminated:
        this.notifyEvent(Events.CALL_HANGUP, inviter);
        this.sip.activeCalls.delete(inviter.id);
        this.sip.cleanupMedia(inviter.id);
        break;
      default:
        throw new Error('Unknown session state.');
    }
  }

  public onReceiveCall(state: SessionState, invitation: Invitation): void {
    switch (state) {
      case SessionState.Initial:
        this.notifyEvent(Events.RECEIVED_CALL, invitation);
        break;
      case SessionState.Establishing:
        break;
      case SessionState.Established:
        const { receivingVideo, tagId } =
          this.sip.setupRemoteMedia(invitation, this.getSpeakerId()) || {};
        this.notifyEvent(Events.CALL_ON_GOING, {
          invitation,
          receivingVideo,
          tagId,
        });
        break;
      case SessionState.Terminating:
      // fall through
      case SessionState.Terminated:
        this.notifyEvent(Events.CALL_HANGUP, invitation);
        this.sip.activeCalls.delete(invitation.id);
        this.sip.cleanupMedia(invitation.id);
        break;
      default:
        break;
    }
  }

  public muteMic(callId: string): void {
    this.notifyEvent(Events.MUTE_MIC, { callId, status: true });
    this.sip.muteMic(callId);
  }

  public unMuteMic(callId: string): void {
    this.notifyEvent(Events.MUTE_MIC, { callId, status: false });
    this.sip.unMuteMic(callId);
  }

  public disableCam = async (callId: string): Promise<void> => {
    if (!this.reinviting) {
      this.reinviting = true;
      this.notifyEvent(Events.MUTE_CAM, { callId, status: true });
      this.updateConstraintsParams({ ...this.constraints, useVideo: false });
      await this.sip.reinvite(callId, this.getConstraints());
      this.reinviting = false;
    }
  };

  public enableCam = async (callId: string): Promise<void> => {
    if (!this.reinviting) {
      this.reinviting = true;
      this.notifyEvent(Events.MUTE_CAM, { callId, status: false });
      this.updateConstraintsParams({ ...this.constraints, useVideo: true });
      await this.sip.reinvite(callId, this.getConstraints());
      this.reinviting = false;
    }
  };

  changeMic = async (callId: string, audioId: string): Promise<void> => {
    if (!this.reinviting) {
      this.reinviting = true;
      this.updateConstraintsParams({ ...this.constraints, audioId });
      await this.sip.reinvite(callId, this.getConstraints());
      this.reinviting = false;
    }
  };

  changeCam = async (callId: string, videoId: string): Promise<void> => {
    if (!this.reinviting) {
      this.reinviting = true;
      this.updateConstraintsParams({
        ...this.constraints,
        useVideo: true,
        videoId,
      });
      await this.sip.reinvite(callId, this.getConstraints());
      this.reinviting = false;
    }
  };

  public holdCall = async (callId: string): Promise<void> => {
    if (!this.reinviting) {
      this.reinviting = true;
      this.notifyEvent(Events.HOLD_CALL, { callId, status: true });
      await this.sip.holdCall(callId);
      this.reinviting = false;
    }
  };

  public async unHoldCall(callId: string): Promise<void> {
    if (!this.reinviting) {
      this.reinviting = true;
      Array.from(this.sip.activeCalls.values())
        .filter(cl => cl.id !== callId)
        .forEach(call => {
          this.sip.holdCall(call.id);
          this.notifyEvent(Events.HOLD_CALL, { callId: call.id, status: true });
        });
      this.notifyEvent(Events.HOLD_CALL, { callId, status: false });
      await this.sip.unHoldCall(callId);
      this.reinviting = false;
    }
  }

  // < -- Observer Pattern https://refactoring.guru/pt-br/design-patterns/observer
  public subscribe(subscribeCallback: SubscriptionCallBack): void {
    this.subscriptions?.set(this.nextSubscriptionId || 0, subscribeCallback);
    if (this.nextSubscriptionId) this.nextSubscriptionId += 1;
  }

  public removeSubscription(id: number): void {
    this.subscriptions?.delete(id);
  }

  public removeAllSubscription(): void {
    this.subscriptions = new Map<number, SubscriptionCallBack>();
  }

  public notifyEvent(event: Events, data: any = null): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(subscribeCallback => {
        if (subscribeCallback) subscribeCallback(event, data);
      });
    }
  }
}
