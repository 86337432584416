export enum Events {
  ONLINE = 'online',
  OFFLINE = 'offline',
  RECONNECTING = 'reconnecting',
  RECONNECTED = 'reconnected',
  MESSAGE = 'message',
  ERROR = 'error',
  PRESENCE = 'presence',
  STANZA = 'stanza',
  RECEIVED = 'received',
  DISPLAYED = 'displayed',
  COMPOSING = 'composing',
  ACTIVE = 'active',
  SEND_EVENT = 'send_event',
  JOIN_ROOM = 'join_room',
}

export enum ChatType {
  CHAT = 'chat',
  GROUPCHAT = 'groupchat',
}
export interface ConnectionOptions {
  service: string;
  domain?: string;
  resource: string;
  username: string;
  password?: string;
}
export interface Presence {
  id: string;
  from: string;
  time: string;
  status: string;
}
export interface Message {
  id: string;
  to: string;
  from: string;
  sent_at: string;
  type: string;
  message: string;
  reply_to: string | undefined;
  reply_msg: string | undefined;
  reply_msg_id: string | undefined;
  eventId?: number;
  eventBody?: string;
}
export interface FileMessage extends Message {
  fileUrl: string;
}
export interface SendingFile {
  firstStepId?: string;
  secondStepId?: string;
  file: File;
  to: string;
  callback: SendImageCallback;
  chatType: ChatType;
}
export interface SendImageCallbackReturn {
  url: string;
  msgId: string;
}
export interface SendImageCallback {
  (data: SendImageCallbackReturn | string, error?: boolean): void;
}
export interface OnlineCallback {
  (): void;
}
export interface OfflineCallback {
  (): void;
}
export interface EventCallback {
  (msg: Message): void;
}
export interface MessageCallback {
  (msg: Message | FileMessage): void;
}
export interface StanzaCallback {
  (stanza: any): void;
}
export interface PresenceCallback {
  (presence: Presence): void;
}
export interface ErrorCallback {
  (error: any): void;
}
export interface SendMessageCallback {
  (msgId: string): void;
}
export interface ReceivedCallback {
  (msgId: string): void;
}
export interface DisplayedCallback {
  (msgId: string): void;
}
export interface ComposingCallback {
  (fromUser: string): void;
}
export interface ActiveCallback {
  (fromUser: string): void;
}
