import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import { RiAndroidFill, RiAppleFill } from 'react-icons/ri';
import axios from 'axios';
import { IDeviceConfig, useAuth } from '../../hooks/auth';
import apiDps from '../../services/dps';

import InputButtonJoin from '../../components/InputButtonJoin';
import CallParticipants from '../../components/CallParticipants';
import MeetConfigVideoButton from '../../components/MeetConfigVideoButton';
import MeetConfigMicButton from '../../components/MeetConfigMicButton';

import './styles.scss';
import logo from '../../assets/logo-squad-transparent.png';
import { SquadVoiceCommunicator } from '../../services/voice/SquadVoiceCommunicator';
import IConferenceApiResponse, {
  IConferenceChatResponse,
  IConferenceVoiceResponse,
} from '../../interfaces/conference';
import { SquadChatCommunicator } from '../../services/SquadChatCommunicator';
import {
  ConferenceEvent,
  ConferenceEvents,
  RequestSpeakEvent,
  SquadEvenstCommunicator,
} from '../../services/SquadEventsCommunicator';
import { camResCheck } from '../../services/utils/camResCheck';
import { IQueryParameters } from '../../services/utils/stringUtils';
import { randomIdParse } from '../../helpers/randomIdParse';
import { parseName } from '../../helpers/parseName';

interface IProps {
  signInAction(): void;
  addSipData(data: SquadVoiceCommunicator): void;
  addChatData(data: SquadChatCommunicator): void;
  conferenceId: string;
  pin: string;
  params: IQueryParameters | undefined;
}

const SignIn: React.FC<IProps> = props => {
  const {
    signInAction,
    addSipData,
    addChatData,
    conferenceId,
    pin,
    params,
  } = props;
  const { signIn, updateDevice } = useAuth();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState<string>(() => {
    if (params?.userName) {
      const parsedSquadUserName = randomIdParse(params.userName);
      localStorage.setItem(
        '@SquadAnnymous:user',
        JSON.stringify({ name: parsedSquadUserName }),
      );
      return parsedSquadUserName;
    }
    const user = localStorage.getItem('@SquadAnnymous:user');
    if (user) {
      const generalUserName = JSON.parse(user).name;
      if (!generalUserName.includes('<squad>')) {
        const parsedName = randomIdParse(generalUserName);
        return parsedName as string;
      }
      return JSON.parse(user).name as string;
    }
    return '';
  });
  const [deviceConfig, setDeviceConfig] = useState<IDeviceConfig>(() => {
    const device = localStorage.getItem('@SquadAnnymous:device');
    if (device) {
      return JSON.parse(device);
    }
    return {} as IDeviceConfig;
  });
  const [mic, setMic] = useState(true);
  const [video, setVideo] = useState(true);
  const [showVideoConfig, setShowVideoConfig] = useState(false);
  const [showMicConfig, setShowMicConfig] = useState(false);
  const [currentAudioDevice, setCurrentAudioDevice] = useState('');
  const [currentSpeakerDevice, setCurrentSpeakerDevice] = useState('');
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [micInputDevices, setMicInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [micOutputDevices, setMicOutputDevices] = useState<MediaDeviceInfo[]>(
    [],
  );
  const [squadEvent] = useState<SquadEvenstCommunicator>(() => {
    const squadEvents = new SquadEvenstCommunicator();
    return squadEvents;
  });
  const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [participantsInCall, setParticipantsInCall] = useState(
    'Aguardando participantes.',
  );
  const [
    chatConference,
    setChatConference,
  ] = useState<IConferenceChatResponse>();
  const [
    voiceConference,
    setVoiceConference,
  ] = useState<IConferenceVoiceResponse>();
  const [conferenceTitle, setConferenceTitle] = useState(
    'Participe da Conferência',
  );

  const updateUser = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(randomIdParse(e.target.value));
  }, []);

  const openVideoConfig = useCallback(() => {
    setShowVideoConfig(!showVideoConfig);
  }, [showVideoConfig]);

  const openMicConfig = useCallback(() => {
    setShowMicConfig(!showMicConfig);
  }, [showMicConfig]);

  const enterConference = useCallback(
    async (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      signIn({ name, device: deviceConfig });

      if (voiceConference && (chatConference || params?.isSquad === 'true')) {
        const sip = new SquadVoiceCommunicator(voiceConference, name);
        addSipData(sip);
        if (chatConference) {
          const squadChat = new SquadChatCommunicator(chatConference, name);
          addChatData(squadChat);
        }
        signInAction();
      }
    },
    [
      signIn,
      name,
      signInAction,
      deviceConfig,
      addSipData,
      addChatData,
      voiceConference,
      chatConference,
      params,
    ],
  );

  const changeMic = useCallback(() => {
    setMic(!mic);
    setDeviceConfig({
      ...deviceConfig,
      useAudio: !mic,
    });
  }, [mic, deviceConfig]);

  const changeAudioDevice = useCallback(
    (audioDevice: string) => {
      const data = {
        ...deviceConfig,
        audioId: audioDevice,
      };

      setDeviceConfig(data);
      updateDevice(data);
    },
    [deviceConfig, updateDevice],
  );

  const changeSpeakerDevice = useCallback(
    (speakerDevice: string) => {
      const data = {
        ...deviceConfig,
        speakerId: speakerDevice,
      };

      setDeviceConfig(data);
      updateDevice(data);
    },
    [deviceConfig, updateDevice],
  );

  const changeVideo = useCallback(() => {
    setVideo(!video);
    setDeviceConfig({
      ...deviceConfig,
      useVideo: !video,
    });
  }, [video, deviceConfig]);

  const changeVideoDevice = useCallback(
    (videoDevice: string) => {
      setDeviceConfig({
        ...deviceConfig,
        videoId: videoDevice,
      });
    },
    [deviceConfig],
  );

  const getMediaDevices = useCallback(async () => {
    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    // eslint-disable-next-line no-console
    console.log(navigator.mediaDevices.getUserMedia);

    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    const deviceVideoList = enumerateDevices.filter(
      item => item.kind === 'videoinput',
    );
    deviceVideoList.forEach(v => {
      camResCheck(v.deviceId);
    });
    const deviceAudioList = enumerateDevices.filter(
      item => item.kind === 'audioinput' && item.deviceId !== 'default',
    );
    const deviceSpeakerList = enumerateDevices.filter(
      item => item.kind === 'audiooutput' && item.deviceId !== 'default',
    );

    if (deviceConfig.videoId === undefined) {
      setDeviceConfig({
        useVideo: video,
        useAudio: mic,
        audioId: deviceAudioList.length > 0 ? deviceAudioList[0].deviceId : '',
        speakerId:
          deviceSpeakerList.length > 0 ? deviceSpeakerList[0].deviceId : '',
        videoId: deviceVideoList.length > 0 ? deviceVideoList[0].deviceId : '',
      });

      setCurrentAudioDevice(
        deviceAudioList.length > 0 ? deviceAudioList[0].deviceId : '',
      );
      setCurrentSpeakerDevice(
        deviceSpeakerList.length > 0 ? deviceSpeakerList[0].deviceId : '',
      );
    } else {
      setCurrentAudioDevice(deviceConfig.audioId);
      setCurrentSpeakerDevice(deviceConfig.speakerId);
      setVideo(deviceConfig.useVideo);
      setMic(deviceConfig.useAudio);
    }

    setDevices(deviceVideoList);
    setMicInputDevices(deviceAudioList);
    setMicOutputDevices(deviceSpeakerList);
  }, [deviceConfig, video, mic]);

  const getConferenceInfo = useCallback(async () => {
    const response = await apiDps.get('', {
      // params: { domain: 'meet.squad.us' },
      // eslint-disable-next-line no-restricted-globals
      params: { domain: location.hostname },
    });
    if (conferenceId.length) {
      if (pin) {
        const responseApi = await axios.get<IConferenceApiResponse>(
          `${response.data.api_url}/conferences/anonymous/${conferenceId}`,
          { params: { pin } },
        );
        const { voice, chat } = responseApi.data;
        setConferenceTitle(voice.conferenceName);
        setChatConference(chat);
        setVoiceConference(voice);
      }
      if (
        params?.isSquad === 'true' &&
        params.conferenceType === 'group' &&
        params.conferenceTitle
      ) {
        setConferenceTitle(decodeURI(params.conferenceTitle));
        setVoiceConference({
          user: params.sipUser,
          password: params.sipPw,
          hostname: params.sipDomain,
          websocketUrl2: params.websocketUrl2,
        } as IConferenceVoiceResponse);
      }
      if (params?.isSquad === 'true')
        setName(decodeURI(params?.userName || ''));
      setLoading(false);
    }
  }, [conferenceId, pin, params]);

  useEffect(() => {
    const { platform, userAgent } = navigator;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );
    if (mobile) {
      setIsMobile(true);
      setIsAndroid(!platform.includes('iPhone' || 'iPad' || 'iPod'));
    } else {
      getMediaDevices();
      getConferenceInfo();
    }
  }, [getMediaDevices, getConferenceInfo, params]);

  useEffect(() => {
    if (conferenceId) {
      squadEvent.connect(conferenceId, (socketEvent, data) => {
        if (socketEvent === ConferenceEvents.REQUEST_SPEAK) {
          data = data as RequestSpeakEvent[];
        } else {
          const participantsList: string[] = [];
          data = data as ConferenceEvent;
          data?.members?.forEach(member => {
            participantsList.push(member.Caller_Caller_ID_Name);
          });

          switch (participantsList.length) {
            case 0:
              setParticipantsInCall('Aguardando participantes.');
              break;
            case 1:
              setParticipantsInCall(
                `${parseName(participantsList[0])} está na reunião.`,
              );
              break;
            case 2:
              setParticipantsInCall(
                `${participantsList
                  .map(n => parseName(n))
                  .join(' e ')} estão na reunião.`,
              );
              break;

            default:
              setParticipantsInCall(
                `${participantsList
                  .map(n => parseName(n))
                  .join(', ')} estão na reunião.`,
              );
              break;
          }
        }
      });
    }
  }, [conferenceId, squadEvent]);

  return (
    <>
      {video && deviceConfig.videoId ? (
        <div className="background">
          <Webcam
            className="myCam"
            videoConstraints={{ deviceId: deviceConfig.videoId }}
          />
        </div>
      ) : (
        <div className="background" />
      )}

      <div className="meet-info">
        <div className="meet-form notAvailableCam">
          {/* 2º Style <div className={`meet-form ${!video && `notAvailableCam`}`}> */}
          <img src={logo} alt="squad" className="logo" />
          {conferenceId === '' || (pin === '' && params?.isSquad !== 'true') ? (
            <span className="title">
              Conferência não informada. Por favor utilize o link recebido no
              convite.
            </span>
          ) : (
            <span className="title">{conferenceTitle}</span>
          )}
          {isMobile ? (
            <div className="links-action">
              <CallParticipants participants={participantsInCall} />
              <a
                className="link-button"
                href={
                  isAndroid
                    ? 'https://play.google.com/store/apps/details?id=com.squadmeet&hl=pt_BR&gl=US'
                    : 'https://apps.apple.com/br/app/squad-meet/id1507938691'
                }
              >
                {isAndroid ? <RiAndroidFill /> : <RiAppleFill />}
                Baixar Squad Meet
              </a>

              <a
                className="link-button"
                href={`squadmeet://domain/app.citrussquad.com/conferenceId/${conferenceId}/pin/${pin}`}
              >
                Abrir no Squad Meet
              </a>
            </div>
          ) : (
            <form onSubmit={enterConference}>
              {conferenceId !== '' &&
                (pin !== '' || params?.isSquad === 'true') && (
                  <>
                    <InputButtonJoin
                      disabledInput={params?.isSquad === 'true'}
                      name={parseName(name)}
                      setName={updateUser}
                      loading={loading}
                      isSquad={params?.isSquad}
                    />
                    <CallParticipants participants={participantsInCall} />
                  </>
                )}
              <div className="meet-config">
                <MeetConfigVideoButton
                  video={video}
                  devices={devices}
                  changeVideo={changeVideo}
                  setShowVideoConfig={openVideoConfig}
                  showVideoConfig={showVideoConfig}
                  changeVideoDevice={changeVideoDevice}
                />
                <MeetConfigMicButton
                  changeMic={changeMic}
                  mic={mic}
                  micInputDevices={micInputDevices}
                  micOutputDevices={micOutputDevices}
                  setShowMicConfig={openMicConfig}
                  showMicConfig={showMicConfig}
                  changeAudioDevice={changeAudioDevice}
                  changeSpeakerDevice={changeSpeakerDevice}
                  currentAudio={currentAudioDevice}
                  currentSpeaker={currentSpeakerDevice}
                  closeConfig={() => setShowMicConfig(false)}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default SignIn;
