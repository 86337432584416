export interface SubscriptionCallBack {
  (event: string, data: any): void;
}
export enum ChatType {
  CHAT = "chat",
  GROUPCHAT = "groupchat",
}
export interface ConnectionOptions {
  service: string;
  domain?: string;
  resource: string;
  username: string;
  password?: string;
}
export interface Presence {
  id: string;
  from: string;
  time: string;
  status: string;
}
export interface Message {
  id: string;
  to: string;
  from: string;
  sent_at: string;
  type: string;
  message: string;
  reply_to: string | undefined;
  reply_msg: string | undefined;
  reply_msg_id: string | undefined;
}
export interface FileMessage extends Message {
  fileUrl: string;
}
export interface SendingFile {
  firstStepId?: string;
  secondStepId?: string;
  thirdStepId?: string;
  file: File;
  to: string;
  callback: SendImageCallback;
  chatType: ChatType;
}
export interface SendImageCallback {
  (imageUrl: string, error?: boolean): void;
}
export interface OnlineCallback {
  (): void;
}
export interface OfflineCallback {
  (): void;
}
export interface MessageCallback {
  (msg: Message | FileMessage): void;
}
export interface StanzaCallback {
  (stanza: any): void;
}
export interface PresenceCallback {
  (presence: Presence): void;
}
export interface ErrorCallback {
  (error: any): void;
}
export interface SendMessageCallback {
  (msgId: string): void;
}
