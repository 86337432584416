import {
  Invitation,
  Inviter,
  Registerer,
  RegistererState,
  Session,
  SessionInviteOptions,
  SessionState,
  UserAgent,
  UserAgentOptions,
  Web,
} from 'sip.js';
import MultiStreamsMixer from 'multistreamsmixer';
import short from 'short-uuid';

interface CustomMediaStreamConstraints extends MediaStreamConstraints {
  customStream?: MediaStream;
}
interface ConnectionListenerCallback {
  (data: RegistererState): void;
}
interface MakeCallCallback {
  (state: SessionState, inviter: Inviter): void;
}
interface ReceiveCallCallback {
  (state: SessionState, invitation: Invitation): void;
}
interface SIPConstructorPropeties {
  user: string;
  password: string;
  wsURL: string;
  domain: string;
  displayName: string;
  connectionCB: ConnectionListenerCallback;
  onMakeCall: MakeCallCallback;
  onReceiveCall: ReceiveCallCallback;
  onDisconnect(e: Error | undefined): void;
  onConnect(): void;
}
// export const tagsRange: number[] = [0, 1, 2, 3];
export const tagsRange: number[] = [0];
export default interface SIP {
  user: string;
  password: string;
  wsURL: string;
  domain: string;
  displayName: string;
  connectionCB: ConnectionListenerCallback;
  onDisconnect(e: Error | undefined): void;
  onConnect(): void;
  onMakeCall: MakeCallCallback;
  onReceiveCall: ReceiveCallCallback;
  userAgent?: UserAgent;
  registerer?: Registerer;
  activeCalls: Map<string, Session>;
  usedTags: Map<string, string>;
  screenShareSession: Session | undefined;
}
export default class SIP {
  constructor(props: SIPConstructorPropeties) {
    Object.assign(this, props);
    this.makeSIP();
    this.activeCalls = new Map<string, Session>();
    this.usedTags = new Map<string, string>();
  }

  makeSIP() {
    const transportOptions = {
      server: this.wsURL,
      // server: "wss://test.citrussquad.com:7443",
      keepAliveInterval: 90,
    };
    const uri = UserAgent.makeURI(`sip:${this.user}@${this.domain}`);
    const userAgentOptions: UserAgentOptions = {
      authorizationUsername: this.user,
      authorizationPassword: this.password,
      displayName: this.displayName,
      transportOptions,
      uri,
      logBuiltinEnabled: true,
      delegate: {
        onInvite: this.onInvite,
      },
      sessionDescriptionHandlerFactory: this.mySessionDescriptionHandlerFactory,
      sessionDescriptionHandlerFactoryOptions: {
        iceGatheringTimeout: 1000,
      },
    };
    this.userAgent = new UserAgent(userAgentOptions);
    this.registerer = new Registerer(this.userAgent);
    this.userAgent.start().then(() => {
      this.registerer?.register();
      this.registerer?.stateChange.addListener(this.connectionCB);
    });
    this.userAgent.transport.onDisconnect = this.onDisconnect;
    this.userAgent.transport.onConnect = this.onConnect;
    window.onbeforeunload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      return this.unRegister();
    };
  }

  Register = () => {
    if (this.userAgent) {
      if (!this.registerer) this.registerer = new Registerer(this.userAgent);
      this.registerer?.register();
      this.registerer?.stateChange.addListener(this.connectionCB);
    }
  };

  unRegister = () => {
    Object.values(this.activeCalls).forEach(c => this.endCall(c));
    this.endScreenShare();
    this.registerer?.unregister();
    this.registerer?.dispose();
    return undefined;
  };

  myMediaStreamFactory: Web.MediaStreamFactory = (
    constraints: CustomMediaStreamConstraints,
    sessionDescriptionHandler: Web.SessionDescriptionHandler,
    // @ts-ignore
  ): Promise<MediaStream> => {
    if (constraints.customStream) {
      return Promise.resolve(constraints.customStream);
    }
    if (!constraints.audio && !constraints.video) {
      return Promise.resolve(new MediaStream());
    }
    if (navigator.mediaDevices === undefined) {
      return Promise.reject(
        new Error('Media devices not available in insecure contexts.'),
      );
    }
    return navigator.mediaDevices.getUserMedia.call(
      navigator.mediaDevices,
      constraints,
    );
  };

  // Create session description handler factory
  mySessionDescriptionHandlerFactory: Web.SessionDescriptionHandlerFactory = Web.defaultSessionDescriptionHandlerFactory(
    this.myMediaStreamFactory,
  );

  invite(
    number: string,
    constraints: MediaStreamConstraints = { video: false, audio: true },
  ) {
    if (!(this.activeCalls.size >= tagsRange.length) && this.userAgent) {
      const destination = UserAgent.makeURI(`sip:${number}@${this.domain}`);
      let newCall;
      if (destination) {
        newCall = new Inviter(this.userAgent, destination, {
          sessionDescriptionHandlerOptions: {
            constraints,
            // @ts-ignore
            offerOptions: {
              offerToReceiveAudio: true,
              offerToReceiveVideo: true,
            },
            iceCheckingTimeout: 1000,
          },
          // inviteWithoutSdp: true,
        });
        this.activeCalls.set(newCall.id, newCall);
      }
      const currentInvite =
        newCall && (this.activeCalls.get(newCall.id) as Inviter);
      if (currentInvite) {
        currentInvite.stateChange.addListener(state => {
          if (this.activeCalls) this.onMakeCall(state, currentInvite);
        });
        currentInvite.invite();
        this.onMakeCall(SessionState.Initial, currentInvite);
      }
    }
  }

  blindTransfer(callId: string, number: string) {
    const destination = UserAgent.makeURI(`sip:${number}@${this.domain}`);
    const call = this.activeCalls.get(callId);
    if (call && destination) {
      call.refer(destination);
      // this.activeCalls.delete(callId);
    }
  }

  attendedTransfer(firstCallId: string, secondCallId: string) {
    const firstCall = this.activeCalls.get(firstCallId);
    const secondCall = this.activeCalls.get(secondCallId);
    if (firstCall && secondCall) {
      firstCall.refer(secondCall);
    }
  }

  getAvailableTag() {
    const tagsUsing = Array.from(this.usedTags.values());
    for (const tagId of tagsRange) {
      if (!tagsUsing.find(tgId => tgId === `remote-stream-${tagId}`)) {
        return `remote-stream-${tagId}`;
      }
    }
  }

  onInvite = (invitation: Invitation) => {
    if (this.activeCalls.size < tagsRange.length) {
      const cb = (session: SessionState) => {
        this.activeCalls.set(invitation.id, invitation);
        this.onReceiveCall(session, invitation);
      };
      invitation.stateChange.addListener(cb);
      this.onReceiveCall(SessionState.Initial, invitation);
    } else {
      invitation.reject();
    }
  };

  endScreenShare = () => {
    if (this.screenShareSession) {
      this.endCall(this.screenShareSession, true);
      this.screenShareSession = undefined;
    }
  };

  endCall(call: string | Session, isScreenShare: boolean = false) {
    if (!isScreenShare) this.endScreenShare();
    const session =
      typeof call === 'string' ? this.activeCalls.get(call) : call;
    if (session) {
      switch (session.state) {
        case SessionState.Initial:
        case SessionState.Establishing:
          if (session instanceof Inviter) {
            // An unestablished outgoing session
            session.cancel();
          } else {
            // An unestablished incoming session
            (session as Invitation).reject();
          }
          break;
        case SessionState.Established:
          // An established session
          session.bye();
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          // Cannot terminate a session that is already terminated
          break;
      }
    }
  }

  setupRemoteMedia(session: Session, speakerId = '') {
    const tagId = this.getAvailableTag();
    if (!tagId) {
      (session as Invitation)?.reject();
      return;
    }
    const mediaElement: HTMLVideoElement = document.getElementById(
      tagId,
    ) as HTMLVideoElement;
    this.usedTags.set(session.id, tagId);
    const remoteStream = new MediaStream();

    (session.sessionDescriptionHandler as Web.SessionDescriptionHandler)?.peerConnection
      ?.getReceivers()
      .forEach(receiver => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
    const playAudio = async (
      mediaElement: HTMLVideoElement,
      remoteStream: MediaStream,
      speakerId: string,
    ) => {
      if (mediaElement) {
        if (speakerId) {
          try {
            await (mediaElement as any).setSinkId(speakerId);
          } catch {}
        }
        mediaElement.srcObject = remoteStream;
        mediaElement.play();
      }
    };
    playAudio(mediaElement, remoteStream, speakerId);
    return {
      receivingVideo: this.remoteVideoEnabled(session),
      tagId,
    };
  }

  remoteVideoEnabled = (session: Session) => {
    let receivingVideo = false;
    (session.sessionDescriptionHandler as Web.SessionDescriptionHandler)?.peerConnection
      ?.getReceivers()
      .forEach(receiver => {
        if (receiver.track) {
          if (receiver.track.kind === 'video') receivingVideo = true;
        }
      });
    return receivingVideo;
  };

  localVideoEnabled = (session: Session) => {
    return !!((session as Inviter)?.sessionDescriptionHandlerOptions
      .constraints as MediaStreamConstraints)?.video;
  };

  cleanupMedia(callId: string) {
    const tag = this.usedTags.get(callId);
    if (!tag) return;
    const mediaElement: HTMLVideoElement = document.getElementById(
      tag,
    ) as HTMLVideoElement;
    if (mediaElement) {
      mediaElement.srcObject = null;
      mediaElement.pause();
      this.usedTags.delete(callId);
    }
  }

  muteMic = (callID: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      const sdh: Web.SessionDescriptionHandler = call.sessionDescriptionHandler as Web.SessionDescriptionHandler;
      sdh?.peerConnection?.getSenders().forEach((stream: any) => {
        if (stream.track?.kind === 'audio') stream.track.enabled = false;
      });
    }
  };

  unMuteMic = (callID: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      const sdh: Web.SessionDescriptionHandler = call.sessionDescriptionHandler as Web.SessionDescriptionHandler;
      sdh?.peerConnection?.getSenders().forEach((stream: any) => {
        if (stream.track?.kind === 'audio') stream.track.enabled = true;
      });
    }
  };
  reinvite = async (callID: string, constraints: MediaStreamConstraints) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      (call.sessionDescriptionHandler as Web.SessionDescriptionHandler).localMediaStream
        .getTracks()
        .forEach(t => t.stop());
      let customStream = new MediaStream();
      if (constraints.audio || constraints.video)
        customStream = await navigator.mediaDevices.getUserMedia.call(
          navigator.mediaDevices,
          constraints,
        );
      const options: SessionInviteOptions = {
        sessionDescriptionHandlerOptions: {
          constraints: {
            customStream,
            audio: { deviceId: short().generate() },
            video: { deviceId: short().generate() },
          },
          // @ts-ignore
          offerOptions: {
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          },
        },
      };
      await call.invite(options);
    }
  };
  disableCam = (callID: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      if (this.localVideoEnabled(call)) {
        const sdh: Web.SessionDescriptionHandler = call.sessionDescriptionHandler as Web.SessionDescriptionHandler;
        sdh?.peerConnection?.getSenders().forEach(stream => {
          if (stream.track?.kind === 'video') stream.track.enabled = false;
        });
      } else {
        // TODO
        console.log('re-invite');
      }
    }
  };

  enableCam = (callID: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      if (this.localVideoEnabled(call)) {
        const sdh: Web.SessionDescriptionHandler = call.sessionDescriptionHandler as Web.SessionDescriptionHandler;
        sdh?.peerConnection?.getSenders().forEach((stream: any) => {
          if (stream.track?.kind === 'video') stream.track.enabled = true;
        });
      } else {
        // TODO
        console.log('re-invite');
      }
    }
  };

  holdCall = (callID: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      const options: SessionInviteOptions = {
        sessionDescriptionHandlerModifiers: [Web.holdModifier],
      };
      return call.invite(options);
    }
  };

  unHoldCall = (callID: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      const options: SessionInviteOptions = {
        sessionDescriptionHandlerModifiers: [],
      };
      if (call) {
        return call.invite(options);
      }
    }
  };

  sendDTMF = (callID: string, dtmf: string) => {
    const call = this.activeCalls.get(callID);
    if (call) {
      call.sessionDescriptionHandler?.sendDtmf(dtmf);
    }
  };

  mixAudios = (firstStream: MediaStream, secondStream: MediaStream) => {
    return new MultiStreamsMixer([firstStream, secondStream]).getMixedStream();
  };

  mergeCalls = (firstCallId: string, secondCallId: string) => {
    const firstCall = this.activeCalls.get(firstCallId);
    const secondCall = this.activeCalls.get(secondCallId);
    if (firstCall && secondCall) {
      const firstPeer = (firstCall.sessionDescriptionHandler as Web.SessionDescriptionHandler)
        ?.peerConnection;
      const secondPeer = (firstCall.sessionDescriptionHandler as Web.SessionDescriptionHandler)
        ?.peerConnection;
      const firstSendedTrack = firstPeer
        ?.getSenders()
        .filter(str => str.track?.kind === 'audio')[0].track;
      const secondSendedTrack = secondPeer
        ?.getSenders()
        .filter(str => str.track?.kind === 'audio')[0].track;
      const firstReceivedStream = new MediaStream();
      const firstReceivedTrack = firstPeer
        ?.getReceivers()
        .filter(str => str.track.kind === 'audio')[0].track;
      firstReceivedTrack && firstReceivedStream.addTrack(firstReceivedTrack);
      const secondReceivedStream = new MediaStream();
      const secondReceivedTrack = secondPeer
        ?.getReceivers()
        .filter(str => str.track.kind === 'audio')[0].track;
      secondReceivedTrack && secondReceivedStream.addTrack(secondReceivedTrack);
      if (
        firstSendedTrack &&
        secondSendedTrack &&
        firstReceivedStream &&
        secondReceivedStream
      ) {
        const firtsLocalMediaStream = new MediaStream();
        const secondLocalMediaStream = new MediaStream();
        firtsLocalMediaStream.addTrack(firstSendedTrack);
        secondLocalMediaStream.addTrack(secondSendedTrack);
        firstPeer
          ?.getSenders()
          .filter(str => str.track?.kind === 'audio')[0]
          .replaceTrack(
            this.mixAudios(
              firtsLocalMediaStream,
              secondReceivedStream,
            ).getAudioTracks()[0],
          );
        secondPeer
          ?.getSenders()
          .filter(str => str.track?.kind === 'audio')[0]
          .replaceTrack(
            this.mixAudios(
              secondLocalMediaStream,
              firstReceivedStream,
            ).getAudioTracks()[0],
          );
      }
    }
  };

  conferenceScreenShare = async (conferenceNumber: string | undefined) => {
    this.endScreenShare();
    if (this.userAgent && conferenceNumber) {
      const destination = UserAgent.makeURI(
        `sip:${conferenceNumber}-screen@${this.domain}`,
      );
      let screenShare: Inviter;
      if (destination) {
        // @ts-ignore
        const customStream: MediaStream = await navigator.mediaDevices.getDisplayMedia(
          navigator.mediaDevices,
          {
            video: { mediaSource: 'screen' },
            audio: true,
          },
        );
        if (!customStream) return;
        screenShare = new Inviter(this.userAgent, destination, {
          params: {
            fromDisplayName: `${this.displayName} (Screen)`,
          },
          sessionDescriptionHandlerOptions: {
            constraints: {
              customStream,
              audio: { deviceId: short().generate() },
              video: { deviceId: short().generate() },
            },
          },
        });
        if (screenShare) {
          customStream.getVideoTracks()[0].onended = () =>
            this.endCall(screenShare);
          this.screenShareSession = screenShare;
          screenShare.invite();
        }
      }
    }
  };
}
