import React, { createContext, useState, useCallback, useContext } from 'react';

interface User {
  name: string;
}

export interface IDeviceConfig {
  videoId: string;
  audioId: string;
  speakerId: string;
  useVideo: boolean;
  useAudio: boolean;
}

interface AuthState {
  user: User;
  device: IDeviceConfig;
}

interface SignInCredentials {
  name: string;
  device: IDeviceConfig;
}

interface AuthContextState {
  user: User;
  device: IDeviceConfig;
  signIn(credentials: SignInCredentials): Promise<void>;
  updateDevice(config: IDeviceConfig): void;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@SquadAnnymous:user');
    const device = localStorage.getItem('@SquadAnnymous:device');

    if (user && device) {
      return {
        user: JSON.parse(user),
        device: JSON.parse(device),
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ name, device }: SignInCredentials) => {
    const user = { name };

    localStorage.setItem('@SquadAnnymous:user', JSON.stringify(user));
    localStorage.setItem('@SquadAnnymous:device', JSON.stringify(device));

    setData({ user, device });
  }, []);

  const updateDevice = useCallback(
    (device: IDeviceConfig) => {
      localStorage.setItem('@SquadAnnymous:device', JSON.stringify(device));
      setData({
        ...data,
        device,
      });
    },
    [data],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        device: data.device,
        signIn,
        updateDevice,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextState => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
