/* eslint-disable consistent-return */
const ResolutionsToCheck = [
  // { width: 1920, height: 1080 },
  // { width: 1600, height: 900 },
  { width: 1366, height: 768 },
  { width: 1280, height: 720 },
  { width: 640, height: 360 },
  { width: 1024, height: 768 },
  { width: 800, height: 600 },
  { width: 720, height: 480 },
  { width: 640, height: 480 },
  { width: 320, height: 240 },
];

let globalDeviceId = 'default';
export const camResCheck = (
  deviceId?: string,
  cb?: (constraints: MediaTrackConstraints | undefined) => void,
): MediaTrackConstraints | undefined => {
  if (deviceId) globalDeviceId = deviceId;
  const savedContraints = localStorage.getItem(
    `@Squad-anonymous:device-${globalDeviceId}`,
  );
  if (savedContraints) {
    try {
      const savedConstraintsParsed = JSON.parse(
        savedContraints,
      ) as MediaTrackConstraints;
      if (cb) cb(savedConstraintsParsed);
      return savedConstraintsParsed;
    } catch (e) {
      localStorage.removeItem(`@Squad-anonymous:device-${globalDeviceId}`);
      //   console.warn(e);
    }
  }
  gum().then(cb);
};
const gum = async (): Promise<
  | {
      deviceId: string;
      width: number;
      height: number;
    }
  | undefined
> => {
  for (let i = 0; i < ResolutionsToCheck.length; i += 1) {
    const resolution = ResolutionsToCheck[i];
    const temporaryConstraints = {
      audio: false,
      video: {
        deviceId: { exact: globalDeviceId },
        width: {
          min: resolution.width,
          max: resolution.width,
        },
        height: {
          min: resolution.height,
          max: resolution.height,
        },
      },
    };
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
    try {
      // eslint-disable-next-line no-await-in-loop
      const stream = await navigator.mediaDevices.getUserMedia(
        temporaryConstraints,
      );
      if (stream) {
        // eslint-disable-next-line no-restricted-syntax
        for (const track of stream.getTracks()) {
          track.stop();
        }
        const constraints = {
          deviceId: globalDeviceId,
          width: resolution.width,
          height: resolution.height,
        };
        localStorage.setItem(
          `@Squad-anonymous:device-${globalDeviceId}`,
          JSON.stringify(constraints),
        );
        return Promise.resolve(constraints);
      }
    } catch (e) {
      //   console.warn(e);
    }
  }
};

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
