import React, { useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { IParticipantItem } from '../../interfaces/participant';
import { Commands } from '../../services/SquadEventsCommunicator';
import ParticipantItem from '../ParticipantItem';

import './styles.scss';

interface IProps {
  participants: IParticipantItem[];
  openParticipants(): void;
  sendCommand(command: Commands, memberId: string | undefined): void;
  isModerator: boolean;
  userMemberId: string;
}

const ParticipantsDrawer: React.FC<IProps> = props => {
  const {
    participants,
    openParticipants,
    sendCommand,
    isModerator,
    userMemberId,
  } = props;
  const containerRef = useRef<HTMLElement>(null);

  return (
    <aside ref={containerRef} className="participants-container">
      <div className="participants-container-header">
        <span>
          Participantes:
          {participants.length}
        </span>
        <RiCloseFill onClick={openParticipants} />
      </div>
      <div className="participants-container-body">
        {participants.map(participant => (
          <ParticipantItem
            participant={participant}
            userMemberId={userMemberId}
            key={participant.memberId}
            sendCommand={sendCommand}
            isModerator={isModerator}
          />
        ))}
      </div>
    </aside>
  );
};

export default ParticipantsDrawer;
