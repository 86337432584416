import React, { memo, useCallback } from 'react';
import {
  RiMicLine,
  RiCameraLine,
  RiMicOffLine,
  RiCameraOffLine,
  RiMacFill,
  RiLogoutBoxRLine,
} from 'react-icons/ri';
import { IParticipantItem } from '../../interfaces/participant';
import { Commands } from '../../services/SquadEventsCommunicator';
import { parseName } from '../../helpers/parseName';
import './styles.scss';

interface IProps {
  participant: IParticipantItem;
  sendCommand(command: Commands, memberId: string | undefined): void;
  isModerator: boolean;
  userMemberId: string;
}

const ParticipantItem: React.FC<IProps> = props => {
  const {
    participant: {
      name,
      avatar,
      status,
      camera,
      mic,
      screenShare,
      talking,
      memberId,
      moderator,
    },
    sendCommand,
    isModerator,
    userMemberId,
  } = props;

  const formatLabelAvatar = useCallback(() => {
    return name.charAt(0).toUpperCase();
  }, [name]);

  const muteUnMuteMic = useCallback(() => {
    if (isModerator) {
      sendCommand('tmute', String(memberId));
    }
  }, [sendCommand, memberId, isModerator]);

  const muteUnMuteCam = useCallback(() => {
    if (isModerator) {
      sendCommand('tvmute', String(memberId));
    }
  }, [sendCommand, memberId, isModerator]);

  const kickParticipant = useCallback(() => {
    if (isModerator) {
      sendCommand('kick', String(memberId));
    }
  }, [sendCommand, memberId, isModerator]);

  const setFloorParticipant = useCallback(() => {
    if (isModerator) {
      sendCommand('vidFloor', String(memberId));
    }
  }, [sendCommand, memberId, isModerator]);

  return (
    <div className="participants-item">
      <div className="participant-info">
        <div className="participant-avatar">
          {avatar !== undefined ? (
            <img src={avatar} alt="participant" />
          ) : (
            <span className="participant-avatar">{formatLabelAvatar()}</span>
          )}
          <span className={`participant-status ${status}`} />
        </div>
        <strong>
          {`${parseName(name)} ${moderator ? '(Moderador)' : ''}`}
        </strong>
      </div>
      <div
        className={`participant-actions moderator-${
          isModerator ? 'on' : 'off'
        }`}
      >
        {mic === 'off' ? (
          <RiMicOffLine className="off" onClick={muteUnMuteMic} />
        ) : (
          <RiMicLine
            className={`talking-${talking ? 'on' : 'off'}`}
            onClick={muteUnMuteMic}
          />
        )}
        {camera === 'off' ? (
          <RiCameraOffLine className="off" onClick={muteUnMuteCam} />
        ) : (
          <RiCameraLine onClick={muteUnMuteCam} />
        )}

        {screenShare === 'off' ? (
          <RiMacFill onClick={setFloorParticipant} />
        ) : (
          <RiMacFill
            className={`talking-${screenShare ? 'on' : 'off'}`}
            onClick={setFloorParticipant}
          />
        )}
        {userMemberId !== String(memberId) && (
          <RiLogoutBoxRLine onClick={kickParticipant} className="kick-item" />
        )}
      </div>
    </div>
  );
};

export default memo(ParticipantItem);
