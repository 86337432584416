/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RiAlertFill, RiAlertLine, RiCloseLine } from 'react-icons/ri';
import Chat from '../../components/Chat';
import ParticipantsDrawer from '../../components/ParticipantsDrawer';
import UserVideo from '../../components/UserVideo';
import VideoCallActions from '../../components/VideoCallActions';
import { useAuth } from '../../hooks/auth';
import IChat from '../../interfaces/chat';
import IMessageChat from '../../interfaces/messageChat';
import { IParticipantItem } from '../../interfaces/participant';
import { SquadChatCommunicator } from '../../services/SquadChatCommunicator';
import {
  Commands,
  ConferenceEvent,
  ConferenceEvents,
  RequestSpeakEvent,
  SquadEvenstCommunicator,
} from '../../services/SquadEventsCommunicator';
import { IQueryParameters } from '../../services/utils/stringUtils';
import { SquadVoiceCommunicator } from '../../services/voice/SquadVoiceCommunicator';
import { Events } from '../../services/voice/types/types';
import './styles.scss';
import UserLayout from './UserLayout/UserLayout';

interface IProps {
  endCall(): void;
  sip?: SquadVoiceCommunicator;
  chat?: SquadChatCommunicator;
  conferenceId: string;
  pin: string;
  params: IQueryParameters | undefined;
}

const Conference: React.FC<IProps> = props => {
  const { endCall, sip, conferenceId, pin, chat, params } = props;
  const { device, updateDevice, user } = useAuth();
  const [oldMessagesLength, setOldMessagesLength] = useState<number>(0);
  const [newMessages, setNewMessages] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [openParticipants, setOpenParticipants] = useState<boolean>(false);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openMyVideo, setOpenMyVideo] = useState<boolean>(false);
  const [callId, setCallId] = useState<string>('');
  const [myMemberId, setMyMemberId] = useState<string>('');
  const [isModerator, setIsModerator] = useState<boolean>(false);
  const [isMuteVideo, setIsMuteVideo] = useState<boolean>(false);
  const [isMuteMic, setIsMuteMic] = useState<boolean>(false);
  const [participants, setParticipants] = useState<IParticipantItem[]>([]);
  const [showModalDevicesConfig, setShowModalDevicesConfig] = useState<boolean>(
    false,
  );
  const [usersWhoseWantToSpeak, setUsersWhoseWantToSpeak] = useState<
    RequestSpeakEvent[]
  >([]);
  const [squadEvent] = useState<SquadEvenstCommunicator>(() => {
    const squadEvents = new SquadEvenstCommunicator();
    return squadEvents;
  });
  const [messages, setMessages] = useState<IMessageChat[]>([]);
  const [chatStarted, setChatStarted] = useState(false);
  const [newMessageBadge, setNewMessageBadge] = useState(false);
  const [initSocket, setInitSocket] = useState(true);
  const [onScreenShare, setOnScreenShare] = useState(false);
  const [userThatIsSharing, setUserThatIsSharing] = useState<
    string | undefined
  >(undefined);
  const [showAdvisor, setShowAdvisor] = useState<boolean>(true);
  const requestSpeak = useCallback(() => {
    squadEvent.sendCommand(conferenceId, 'requestSpeak', user.name);
  }, [conferenceId, squadEvent, user]);

  const unMuteCam = useCallback(() => {
    if (sip) {
      sip.enableCam(callId);
      setIsMuteVideo(false);
      updateDevice({
        ...device,
        useVideo: true,
      });
      squadEvent.sendCommand(conferenceId, 'tvmute', myMemberId);
    }
  }, [sip, updateDevice, device, conferenceId, myMemberId, squadEvent, callId]);

  const muteCam = useCallback(() => {
    if (sip) {
      sip.disableCam(callId);
      setIsMuteVideo(true);
      updateDevice({
        ...device,
        useVideo: false,
      });
      squadEvent.sendCommand(conferenceId, 'tvmute', myMemberId);
    }
  }, [sip, updateDevice, device, conferenceId, myMemberId, squadEvent, callId]);

  const unMuteMic = useCallback(() => {
    if (sip) {
      sip.unMuteMic(callId);
      setIsMuteMic(false);
      updateDevice({
        ...device,
        useAudio: true,
      });
      squadEvent.sendCommand(conferenceId, 'tmute', myMemberId);
    }
  }, [sip, updateDevice, device, conferenceId, myMemberId, squadEvent, callId]);

  const muteMic = useCallback(() => {
    if (sip) {
      sip.muteMic(callId);
      setIsMuteMic(true);
      updateDevice({
        ...device,
        useAudio: false,
      });
      squadEvent.sendCommand(conferenceId, 'tmute', myMemberId);
    }
  }, [sip, updateDevice, device, conferenceId, myMemberId, squadEvent, callId]);

  const onEndCall = useCallback(async () => {
    if (sip) {
      sip.endCall(callId);
      squadEvent.disconnect();
      if (onScreenShare) {
        sip.endScreenShare();
      }
      await chat?.chat.client.stop();
      endCall();
    }
  }, [endCall, sip, callId, squadEvent, onScreenShare, chat]);

  const getUserMedia = useCallback(async () => {
    const number =
      params?.isSquad && params.conferenceType === 'group'
        ? `citrus-conference-authenticated-${conferenceId}`
        : `citrus-conference_${conferenceId}_${pin}`;

    if (sip) {
      sip.subscribe((event, data) => {
        if (event === Events.MAKE_CALL) {
          setCallId(data.id);
        }
        if (event === Events.CALL_HANGUP) {
          onEndCall();
        }

        if (event === Events.CALL_ON_GOING) {
          if (!device.useVideo) {
            setTimeout(() => {
              setIsMuteVideo(true);
              squadEvent.sendCommand(conferenceId, 'vmute', myMemberId);
            }, 1000);
          }
        }
      });
      sip.makeCall(number, device);
    }
  }, [
    sip,
    device,
    conferenceId,
    pin,
    myMemberId,
    squadEvent,
    onEndCall,
    params,
  ]);

  const openChatAction = useCallback(() => {
    setOpenParticipants(false);
    setOpenChat(!openChat);
    setNewMessageBadge(false);
  }, [openChat]);

  const openParticipantsAction = useCallback(() => {
    setOpenChat(false);
    setOpenParticipants(!openParticipants);
  }, [openParticipants]);

  const changeLayout = useCallback(
    (layout: string) => {
      squadEvent.sendCommand(conferenceId, 'setLayout', layout);
    },
    [squadEvent, conferenceId],
  );

  const sendCommand = useCallback(
    (command: Commands, memberId: string | undefined) => {
      if (memberId === myMemberId) {
        switch (command) {
          case 'tmute':
            if (!isMuteMic) {
              muteMic();
            } else {
              unMuteMic();
            }
            break;
          case 'tvmute':
            if (!isMuteVideo) {
              muteCam();
            } else {
              unMuteCam();
            }
            break;
          default:
            break;
        }
      } else {
        squadEvent.sendCommand(conferenceId, command, memberId);
      }
    },
    [
      squadEvent,
      conferenceId,
      muteMic,
      unMuteMic,
      isMuteMic,
      myMemberId,
      isMuteVideo,
      muteCam,
      unMuteCam,
    ],
  );

  const screenShare = useCallback(async () => {
    if (sip) {
      if (!onScreenShare) {
        try {
          await sip.screenShare(conferenceId);
          setUserThatIsSharing(user.name);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          setUserThatIsSharing(undefined);
        }
      } else {
        sip.endScreenShare();
        setUserThatIsSharing(undefined);
      }
    }
  }, [sip, conferenceId, onScreenShare, user.name]);

  const initChat = useCallback(async () => {
    if (chat && !chatStarted) {
      try {
        await chat.makeChat((event, data) => {
          // console.log(event);
          // console.log(event);
        });
        const jid = `${conferenceId}@conference.${chat.getXmmppDomain()}`;
        chat.joinGroup(jid);
        setChatStarted(true);
      } catch {
        // console.log('Falha ao conectar com o chat');
      }
    }
  }, [chat, conferenceId, chatStarted]);

  const sendMessage = useCallback(
    (text: string) => {
      const jid = `${conferenceId}@conference.${chat?.getXmmppDomain()}`;
      const chatData = {
        jid,
      } as IChat;
      chat?.sendMessage(chatData, text, () => {
        // mensagem enviada
      });
    },
    [chat, conferenceId],
  );

  const sendFile = useCallback(
    (files: File[]) => {
      const jid = `${conferenceId}@conference.${chat?.getXmmppDomain()}`;
      const chatData = {
        jid,
      } as IChat;
      chat?.sendFiles(chatData, files, () => {
        // arquivo enviado
      });
    },
    [chat, conferenceId],
  );

  const isUserSharing = (participantName: string) => {
    if (
      participants.find(
        item => item.name === participantName.concat(' (Screen)'),
      )
    ) {
      return 'onScreenShare';
    }
    return '';
  };

  useEffect(() => {
    getUserMedia();
    initChat();
  }, [getUserMedia, initChat]);

  useEffect(() => {
    if (messages.length > 0) {
      if (oldMessagesLength < messages.length) {
        if (!openChat) {
          setNewMessages(true);
        }
        setOldMessagesLength(messages.length);
      }
    }
  }, [messages.length, oldMessagesLength, openChat]);

  const onSquadEvents = useCallback(() => {
    if (conferenceId) {
      squadEvent.connect(conferenceId, (socketEvent, data) => {
        if (socketEvent === ConferenceEvents.REQUEST_SPEAK) {
          data = data as RequestSpeakEvent[];
          setUsersWhoseWantToSpeak(
            data.filter(rq => rq.conferenceId === conferenceId),
          );
        } else {
          const participantsList: IParticipantItem[] = [];
          data = data as ConferenceEvent;
          data?.members?.forEach(member => {
            participantsList.push({
              camera: member.See ? 'on' : 'off',
              mic: member.Speak ? 'on' : 'off',
              name: member.Caller_Caller_ID_Name,
              talking: member.Talking,
              screenShare: member.Floor ? 'on' : 'off',
              status: 'on',
              memberId: member['Member-ID'],
              moderator: member['Member-Type'] === 'moderator',
            });
            const memberScreen = member.Caller_Caller_ID_Name.replace(
              '(Screen)',
              '',
            );

            if (
              member.Caller_Caller_ID_Name.includes('(Screen)') &&
              memberScreen.includes(user.name)
            ) {
              setOnScreenShare(true);
              if (member.Caller_Caller_ID_Name.includes(user.name)) {
                setUserThatIsSharing(member.Caller_Caller_ID_Name);
              }
            } else {
              setOnScreenShare(false);
              setUserThatIsSharing(undefined);
            }

            if (user.name === member.Caller_Caller_ID_Name) {
              setIsModerator(member['Member-Type'] === 'moderator');
              setMyMemberId(String(member['Member-ID']));

              switch (member.Action) {
                case 'mute-member':
                  setIsMuteMic(true);
                  break;
                case 'unmute-member':
                  setIsMuteMic(false);
                  break;
                case 'vmute-member':
                  setIsMuteVideo(true);
                  break;
                case 'unvmute-member':
                  setIsMuteVideo(false);
                  break;
                default:
                  break;
              }

              if (member.Action === 'mute-member') {
                setIsMuteMic(true);
              }
            }
          });
          setParticipants(participantsList);
        }
      });
    }
  }, [conferenceId, squadEvent, user]);
  const initDevices = useCallback(() => {
    if (initSocket && myMemberId) {
      if (!device.useAudio) {
        squadEvent.sendCommand(conferenceId, 'tmute', myMemberId);
      }

      if (!device.useVideo) {
        squadEvent.sendCommand(conferenceId, 'tvmute', myMemberId);
      }

      setInitSocket(false);
    }
  }, [
    conferenceId,
    device.useAudio,
    device.useVideo,
    initSocket,
    myMemberId,
    squadEvent,
  ]);
  useEffect(initDevices, [initDevices]);
  const onChatEvent = useCallback(() => {
    if (chat) {
      const messagesList: IMessageChat[] = [];
      chat.subscribe((event, data) => {
        if (event === 'message') {
          const name = data.fromUser.split('_').join(' ');
          const messageData: IMessageChat = {
            id: data.id,
            content: data.message,
            name,
            isFileMessage: data.isFileMessage,
            isImageMessage: data.isImageMessage,
            date: data.deliverTimestamp,
            type:
              data.fromUser.split('_').join(' ') === data.userName
                ? 'my-message'
                : 'another-message',
          };
          messagesList.push(messageData);
          setMessages([]);
          setMessages(messagesList);
        }
      });
    }
  }, [chat]);

  const toggleAdvisor = () => {
    setShowAdvisor(!showAdvisor);
  };

  useEffect(() => {
    onChatEvent();
  }, [onChatEvent]);

  useEffect(() => {
    onSquadEvents();
  }, [onSquadEvents]);

  return (
    <div className="conference-page">
      <div className="video-layout-container">
        <video
          ref={videoRef}
          autoPlay
          className={`layout-call ${participants.length > 6 && 'expand'}`}
          id="remote-stream-0"
        />
      </div>
      {showAdvisor && (
        <header className={`advisor ${participants.length > 6 && 'expand'}`}>
          <RiAlertFill size={20} />
          <text id="text1">Squad has started recording!</text>
          <text id="text2">
            This meeting is being recorded. By joining, you are giving consent
            for this meeting to be recorded.
          </text>
          <text id="text3">Privacy policy</text>
          <RiCloseLine
            onClick={() => toggleAdvisor()}
            className="advisor-button"
          />
        </header>
      )}
      <aside
        className={`users-layout-container ${
          participants.length > 6 && 'expand'
        }`}
      >
        <body
          className={`users-layout-content loading ${
            participants.length > 6 && 'expand'
          }`}
        >
          {participants ? (
            participants.map(user => {
              if (user && !user.name.includes('(Screen)')) {
                return (
                  <UserLayout
                    user={user}
                    key={user.memberId}
                    mic={user.mic}
                    talking={user.talking}
                    name={user.name}
                    profileImgUrl={user.avatar ? user.avatar : undefined}
                    onScreenShare={onScreenShare}
                    userThatIsSharing={isUserSharing(user.name as string)}
                    handsUp={
                      !!usersWhoseWantToSpeak.find(
                        item => item.userName === user.name,
                      )
                    }
                  />
                );
              }
              return <></>;
            })
          ) : (
            <div className="loading-text">Carregando participantes...</div>
          )}
        </body>
      </aside>
      <VideoCallActions
        endCall={onEndCall}
        openParticipants={openParticipantsAction}
        openChat={openChatAction}
        openMyVideo={() => setOpenMyVideo(!openMyVideo)}
        muteCam={muteCam}
        unMuteCam={unMuteCam}
        muteMic={muteMic}
        unMuteMic={unMuteMic}
        video={!isMuteVideo}
        mic={!isMuteMic}
        newMessage={newMessageBadge}
        inScreenShare={onScreenShare}
        screenShare={screenShare}
        changeLayout={changeLayout}
        participants={participants}
        usersWhoseWantToSpeak={usersWhoseWantToSpeak}
        setUsersWhoseWantToSpeak={setUsersWhoseWantToSpeak}
        requestSpeak={requestSpeak}
        isSquad={params?.isSquad === 'true'}
        newMessages={newMessages}
        setNewMessages={setNewMessages}
        showModalDevicesConfig={showModalDevicesConfig}
        setShowModalDevicesConfig={setShowModalDevicesConfig}
      />
      {openParticipants && (
        <ParticipantsDrawer
          participants={participants}
          openParticipants={() => setOpenParticipants(!openParticipants)}
          sendCommand={sendCommand}
          isModerator={isModerator}
          userMemberId={myMemberId}
        />
      )}
      <div className={`chat-${openChat ? 'open' : 'close'}`}>
        <Chat
          closeChat={() => setOpenChat(false)}
          sendMessage={sendMessage}
          sendFile={sendFile}
          messages={messages}
        />
      </div>
      <UserVideo openVideo={openMyVideo} />
    </div>
  );
};

export default Conference;
