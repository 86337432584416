import React from 'react';
import { HashRouter } from 'react-router-dom';
import Routes from './routes';
import './styles/index.scss';
import { AppProvider } from './hooks';

const App: React.FC = () => {
  return (
    <AppProvider>
      <HashRouter>
        <Routes />
      </HashRouter>
    </AppProvider>
  );
};

export default App;
