import React from 'react';

import './styles.scss';

interface IProps {
  participants: string;
}

const CallParticipants: React.FC<IProps> = props => {
  const { participants } = props;
  return (
    <div className="conference-users-joined">
      {participants === '' ? (
        <span>Aguardando participantes</span>
      ) : (
        <span
          style={{
            maxWidth: '350px',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            textOverflow: 'ellipsis',
          }}
        >
          {participants}
        </span>
      )}
    </div>
  );
};

export default CallParticipants;
