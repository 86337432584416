import React, { useEffect } from 'react';
import CardLoadingConference from '../../components/CardLoadingConference';

import './styles.scss';

interface IProps {
  onLoading(): void;
}

const LoadingConference: React.FC<IProps> = props => {
  const { onLoading } = props;

  useEffect(() => {
    onLoading();
  }, [onLoading]);

  return (
    <div className="loading-conference">
      <CardLoadingConference />
    </div>
  );
};

export default LoadingConference;
